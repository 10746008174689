import { formatTime12hr, formatLocalTimeZoneAbbrv, formatTimeMinutesSeconds_seconds } from '../../utils/dateUtils';

export const namespaced = true;

export const state = {
  metadata: {
    station: '',
    songOrShow: '',
    artist: '',

    podcastName: '',
    podcastInfo: '',
    episodeName: '',
    episodeInfo: '',

    start: 0,
    stop: 0,
    duration: '',
    airedTimes: '',

    skips: 6,
    isSkippable: '',
    canPause: '',
    image: ''
  },

  stationPosterImageUrl: '',
  audacyPosterImageUrl: 'https://images.radio.com/logos/Audacy_generic_300x250.jpg',

  airedText: ['Aired Times', 'Aired', 'On-Air Until', 'Airing'],
  stateText: ['Live On-Air', 'Podcast Episode', 'Audio Clip', 'Exclusive Stations'],
  currentAiredText: '',
  currentStreamText: ''
};

//Vue.set(state.arr, index, payload);
export const mutations = {
  SET_METADATA(state, payload) {
    state.metadata.station = payload.station;
    state.metadata.songOrShow = payload.songOrShow;
    state.metadata.artist = payload.artist;

    state.metadata.podcastName = payload.podcastName;
    state.metadata.podcastInfo = payload.podcastInfo;
    state.metadata.episodeName = payload.episodeName;
    state.metadata.episodeInfo = payload.episodeInfo;

    state.metadata.start = payload.start;
    state.metadata.stop = payload.stop;
    state.metadata.duration = payload.duration;
    state.metadata.airedTimes = payload.airedTimes;

    state.metadata.skips = payload.skips;
    state.metadata.isSkippable = payload.isSkippable;
    state.metadata.canPause = payload.canPause;

    state.metadata.image = payload.image;
  },

  SET_AIRED_TIMES(state, airedTimes) {
    state.metadata.airedTimes = airedTimes;
  },

  SET_CURRENT_STREAM_TEXT(state, payload) {
    state.currentStreamText = payload;
  },

  SET_STATION_POSTER_IMAGE_URL(state, payload) {
    state.stationPosterImageUrl = payload;
  }
};
// context obj  {state, rootstate, commit, dispatch, getters, rootGetters}
export const actions = {
  updateMetadata({ commit }, payload) {
    payload.songOrShow = payload.songOrShow || payload.title;
    commit('SET_METADATA', payload);
  },

  // which is best practice, getting item from rootState or from rootGetter?
  updateAiredTimes({ commit, state }) {
    let airedTimes = '';
    const category = rootstate.state.category;
    const playerType = rootstate.state.playerType;

    if (playerType !== 'embedded-player' && (category === 'sports' || category === 'news' || category === 'talk')) {
      const now = Date.now();

      if (state.metadata.start && state.metadata.stop) {
        const startWith = now > state.metadata.start && now < state.metadata.stop ? 'Airing now' : 'Aired';
        const localTimeZoneAbbrv = formatLocalTimeZoneAbbrv();
        airedTimes = `${startWith} ${getShowTimes12hrFormat.start} to ${getShowTimes12hrFormat.stop} ${localTimeZoneAbbrv}`;
      }
    }

    commit('SET_AIRED_TIMES', airedTimes);
  },

  updateStreamTypeText({ commit }, payload) {
    let text;

    switch (payload.toLowerCase()) {
      case 'rewind':
      case 'rewound':
        text = 'Audio Clip';
        break;
      case 'episode':
        text = 'Podcast Episode';
        break;
      case 'digital':
        text = 'Exclusive Station';
        break;
      default:
        text = 'Live Radio';
    }

    commit('SET_CURRENT_STREAM_TEXT', text);
  },

  updateStationPosterImageUrl({ commit }, payload) {
    commit('SET_STATION_POSTER_IMAGE_URL', payload);
  }
};

export const getters = {
  getMetadata: state => {
    return state.metadata;
  },

  getPosterUrl: state => {
    if (state.metadata.image && state.metadata.image !== '') {
      return state.metadata.image;
    } else if (state.stationPosterImageUrl && state.stationPosterImageUrl !== '') {
      return state.stationPosterImageUrl;
    } else {
      return state.audacyPosterImageUrl;
    }
  },

  getAiredText: state => {
    let index = 0;
    return state.airedText[index];
  },

  getSkips: state => {
    return state.metadata.skips;
  },

  getShowTimes12hrFormat: state => {
    return { start: formatTime12hr(state.metadata.start), stop: formatTime12hr(state.metadata.stop) };
  },

  getShowTimesMinSecFormat: state => {
    return {
      start: formatTimeMinutesSeconds_seconds(state.metadata.start * 1000),
      stop: formatTimeMinutesSeconds_seconds(state.metadata.stop * 1000)
    };
  },

  getDurationMinSecFormat: state => {
    return formatTimeMinutesSeconds_seconds(state.metadata.stop * 1000);
  },

  getCurrentStreamText: state => {
    return state.currentStreamText;
  },

  getStationPosterImageUrl: state => {
    return state.stationPosterImageUrl;
  }
};
