import Vue from 'vue';
import PlayerContainer from './PlayerContainer.vue';
import store from './store';

console.log('%c**************************************************', 'color: Plum');
console.log('%cVue.js version: ', 'color: Plum', Vue.version);

// testing remote
Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(PlayerContainer)
}).$mount('#app');
