/* eslint-disable prettier/prettier */
import { http } from '../../utils/utils';
import { formatTime12hrWithSeconds } from '../../utils/dateUtils';
export const namespaced = true;

export const state = {
  showShareDialog: false,
  shareLink: 'https://sharing.radio.com/sharing',
  copyLinkText: 'Copy Link',
  shareType: 'timecode',
  shareClockTime: 0,
  loading: false,
  SHARE_PROD: 'https://sharing.radio.com/sharing',
  SHARE_STG:'https://sharing.radio-stg.com/sharing',
  BEGINNING: 'beginning',
  TIMECODE: 'timecode',
  WEB: 'web',
};

export const mutations = {
  SET_SHOW_SHARE_DIALOG(state, payload) {
    state.showShareDialog = payload;
  },
  SET_SHARE_LINK(state, payload) {
    state.shareLink = payload;
  },
  SET_COPY_LINK_TEXT(state, payload) {
    state.copyLinkText = payload;
  },
  SET_SHARE_TYPE(state, payload) {
    state.shareType = payload;
  },
  SET_SHARE_CLOCK_TIME(state, payload) {
    state.shareClockTime = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  }
};

export const actions = {
  setShowShareDialog({ commit }, payload) {
    commit('SET_SHOW_SHARE_DIALOG', payload);
  },
  setCopyLinkText({ commit }, payload) {
    commit('SET_COPY_LINK_TEXT', payload);
  },
  setShareType({ commit }, payload) {
    commit('SET_SHARE_TYPE', payload);
  },
  setShareClockTime({ commit }, payload) {
    commit('SET_SHARE_CLOCK_TIME', payload);
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  async getSharingUrl({ commit, rootState, state }) {
    let id = rootState.station ? rootState.station.id : 0;
    let timestamp = state.shareType === state.TIMECODE ? Math.floor(state.shareClockTime / 1000) : rootState.rewindTime ? Math.floor(rootState.rewindTime / 1000) : 0;
    let shareType = state.shareType;
    const body = {
      station_id: id,
      timestamp: timestamp,
      share_type: shareType,
      platform: 'web',
    };
    try {
      commit('SET_LOADING', true);
      const resp = await http(state.SHARE_PROD, 'POST', body);
      commit('SET_SHARE_LINK', resp.url);
      if (+resp.status === 404) {
        throw (new Error('Could not generate link.'));
      }

    } catch (error) {
      commit('SET_COPY_LINK_TEXT', 'Could not generate link.');
      console.log('error getting share link', error);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

export const getters = {
  getShowShareDialog: state => {
    return state.showShareDialog;
  },
  getShareLink: state => {
    return state.shareLink;
  },
  getCopyLinkText: state => {
    return state.copyLinkText;
  },
  getShareType: state => {
    return state.shareType;
  },
  getShareClockTime: state => {
    return formatTime12hrWithSeconds(state.shareClockTime);
  },
  isLoading: state => {
    return state.loading;
  }
};
