<template>
  <div :id="railId" :class="railClass" :style="computedFract" v-on="$listeners" @click="railClicked"></div>
</template>

<script>
export default {
  name: 'BaseRails',
  inheritAttrs: false,

  props: {
    baseRailId: { type: String },
    railId: { type: String },
    railClass: { type: String },
    railFract: { type: Number },
    railStyle: { type: Object },
    slideDirection: { type: String },
    railClickable: { type: Boolean }
  },

  data() {
    return {
      // element objs
      baseRail_ele: {},

      // for computational purposes
      baseRail_width: 0,
      baseRail_left: 0,
      baseRail_top: 0,
      baseRail_height: 0,
      computedRail_fract: 0
    };
  },

  computed: {
    /*
     *
     */
    computedFract: function() {
      let style;

      if (this.railId === 'volume-railBase') {
        style = { height: 100 + '%' };
      } else if (this.railId === 'volume-railFill') {
        style = { height: this.railFract * 100 + '%' };
      } else if (this.railId === 'scrubbar-railLoaded') {
        style = { width: this.railFract * 100 + '%' };
      } else if (this.railId === 'scrubbar-railProgress') {
        style = { width: this.railFract * 100 + '%' };
      }

      return style;
    }
  },

  mounted() {
    this.baseRail_ele = document.getElementById(this.baseRailId);

    if (this.slideDirection === 'horizontal') {
      this.baseRail_width = this.baseRail_ele.offsetWidth;
      this.baseRail_left = this.baseRail_ele.getBoundingClientRect().left;
    } else if (this.slideDirection === 'vertical') {
      this.baseRail_height = this.baseRail_ele.offsetHeight;
    }
  },

  methods: {
    /*
     *
     */
    railClicked(event) {
      if (this.railClickable) {
        if (this.slideDirection === 'horizontal') {
          let pos = event.clientX - this.baseRail_left;
          this.computedRail_fract = pos / this.baseRail_width;

          this.$emit('rcvdFract', this.computedRail_fract);
        } else if (this.slideDirection === 'vertical') {
          this.baseRail_top = this.baseRail_ele.getBoundingClientRect().top;
          this.pos = event.clientY - this.baseRail_top;
          this.computedRail_fract = 1 - this.pos / this.baseRail_height;

          this.$emit('rcvdFract', this.computedRail_fract);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/_variables2.scss';

/******************************
 * WEB-PLAYER SCRUBBAR RAILS
/*****************************/
#playerContainer.digital #scrubbar .scrubbar-rail {
  cursor: default;
}

#scrubbar-railBase {
  width: 100%;
  background: $scrubbar_base_color;
}

#scrubbar-railLoaded {
  background: $scrubbar_loaded_color;
}

#scrubbar-railProgress {
  background: $scrubbar_progress_color;
}

.scrubbar-rail {
  position: absolute;
  top: 50%;
  height: $scrubbar_rail_height;
  border-radius: 5px;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
}

/****************************
 * WEB-PLAYER VOLUME RAILS
/***************************/

.volume-rail {
  position: absolute;
  bottom: 0px;
  width: 6px;
  border-radius: 5px;
  cursor: pointer;
}

#volume-railBase {
  background: $clr_white_off;
}

#volume-railFill {
  height: 50%;
  background: linear-gradient(to top, $clr_dusk_light, $clr_purple_dark);
}
</style>
