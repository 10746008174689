export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const isIOSDevice = () => {
  return (
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)
  );
};

// for help with exclusive stations on mobile ios devices
export const isMobileIOSDevice = () => {
  return navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i);
};

/**
 * Update a copy of the controls state (that's been de-referenced) with a copy of station types default state.
 * Uses the array passed in and set those properties TO SHOW, etc.
 * The play button is not set here, only all the other buttons.
 * @currControlsState an object that is a de-referenced copy of the current state.
 * @arr an array of property names that should be set to show.
 * @return = an updated 'currControlsState' obj.
 */
export function setPropertiesToShow(currControlsState, arr) {
  for (let i = 0; i < arr.length; i++) {
    currControlsState[arr[i]].disabled = false;
    currControlsState[arr[i]].show = true;
    delete currControlsState[arr[i]].aNotUsed;

    // additional properties to be set
    if (arr[i] === 'scrubbar') currControlsState[arr[i]].clickable = true;
    if (arr[i] === 'scrubThumb') currControlsState[arr[i]].draggable = true;
    if (arr[i] === 'thumbUp' || arr[i] === 'thumbDown') currControlsState[arr[i]].clicked = false;
    // prev is used for digital, but is only just an image, it is not a truly working button.
    if (arr[i] === 'previous') currControlsState[arr[i]].disabled = true;
  }

  return currControlsState;
}

/**
 * Update a copy of the controls state (that's been de-referenced) with a copy of station types default state.
 * Uses the array passed in and set those properties to NOT TO SHOW, etc.
 * The property 'aNotUsed' is only for dev purposes and can be removed.  Just a quick reference about that property.
 * @currControlsState an object that is a de-referenced copy of the current state.
 * @arr an array of property names that should be set to show.
 * @return = an updated 'currControlsState' obj.
 */
export function initPropertiesToHidden(currControlsState, arr) {
  for (let i = 0; i < arr.length; i++) {
    currControlsState[arr[i]].disabled = true;
    currControlsState[arr[i]].show = false;
    currControlsState[arr[i]].aNotUsed = true;
  }

  return currControlsState;
}

// KL - grabbed this from here - https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}

// KL - grabbed this function form a vue/async article... probably a bit much...
export async function http(url, method = 'GET', data) {
  const body = JSON.stringify(data);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const response = await fetch(url, {
    headers: headers,
    method: method,
    body: body
  });

  return await response.json();

}

export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this, args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
