<template>
  <base-buttons-others id="shareButton" class="pillBtn shareBtn" @click="openShareDialog(true)">Share Audio</base-buttons-others>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButtonsOthers from './baseComponents/BaseButtonsOthers.vue';
export default {
  name: 'ShareButton',

  components: {
    BaseButtonsOthers
  },

  methods: {
    ...mapActions({
      openShareDialog: 'sharing/setShowShareDialog'
    })
  }
};
</script>

<style lang="scss" scoped></style>
