<template>
  <a :href="websiteHref" target="_blank" class="audacyLogo"></a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OpenSdpControl',

  computed: {
    ...mapGetters({
      websiteHref: 'getWebsiteHref'
    })
  },

  methods: {
    openSdp() {
      ExchangeService.openRDCClick();
    }
  }
};
</script>

<style lang="scss" scoped>

  @import '../assets/_variables2.scss';

  .audacyLogo {
    position:absolute;
    top:15px;
    right:15px;
    height:15.9375px;
    width:20px;
    background: $logo_a_solid_white;
    background-size: cover;
  }

</style>
