/*
  These storage functions are wrapped with a try/catch because a DOMExpection error is sometimes thrown just after load of the
  embed player in Chrome Incognito and this seems to solve the issue.  ~Ken/Liz 01/21

  Also storage will not be accessible for this combination: Incognito / Iframe / Domain name difference.  Storage just seems to
  be totally unaccessaible.  ~Liz 01/21
*/

// This is a prefix that will be added to the storage key for our product and so help prevent naming collisions and will help
// identify the player's storage properties from the others.
const ap = 'AudacyPlayer_';
let haveLoggedWarning = false;

function logStorageWarning(msg, err) {
  let additionalInfo = [];

  err && err.name && additionalInfo.push(err.name);
  err && err.message && additionalInfo.push(err.message);

  if (!haveLoggedWarning) {
    console.warn(msg, additionalInfo.join(' '));
    haveLoggedWarning = true;
  }
}

// --- SESSION STORAGE ---
export function getSessionStorageItem(payload) {
  try {
    let value = sessionStorage.getItem(`${ap}${payload.key}`);

    // incase undefined gets stored in Session storage, it'll be a stored as a string. ~Liz
    if (value === 'undefined') value = undefined;

    return value;
  } catch (e) {
    logStorageWarning('Session storage is not ready or not supported.  Error:', e);
  }
}

export function setSessionStorageItem(payload) {
  try {
    sessionStorage.setItem(`${ap}${payload.key}`, payload.value);
  } catch (e) {
    // console.error('***** !!! Sesion storage is not ready or not supported. Error:', e);
  }
}

export function removeSessionStorageItem(payload) {
  try {
    sessionStorage.removeItem(`${ap}${payload.key}`);
  } catch (e) {
    logStorageWarning('Session storage is not ready or not supported.  Error:', e);
  }
}

// --- LOCAL STORAGE ---
export function getLocalStorageItem(payload) {
  try {
    return localStorage.getItem(`${ap}${payload.key}`);
  } catch (e) {
    logStorageWarning('Local storage is not ready or not supported.  Error:', e);
  }
}

export function setLocalStorageItem(payload) {
  try {
    localStorage.setItem(`${ap}${payload.key}`, payload.value);
  } catch (e) {
    logStorageWarning('Local storage is not ready or not supported.  Error:', e);
  }
}

export function removeLocalStorageItem(payload) {
  try {
    localStorage.removeItem(`${ap}${payload.key}`);
  } catch (e) {
    logStorageWarning('Local storage is not ready or not supported.  Error:', e);
  }
}
