<template>
  <div class="ad-markers">
    <base-marker v-for="(fract, index) in fracts" :key="index" :fract="fract" :time="times[index]" :index="index" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseMarker from './baseComponents/BaseMarker';

export default {
  components: {
    BaseMarker
  },

  computed: {
    ...mapGetters({
      fracts: 'scrubbar/getAdBreaksFracts',
      times: 'scrubbar/getAdBreakTimes'
    })
  }
};
</script>

<style lang="scss" scoped>
@import './../assets/_variables2.scss';

.ad-markers {
  position: absolute;
  top: $scrubbar_marker_top_pos;
  width: 100%;
}
</style>
