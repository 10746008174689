// all controls that are affected by state changes
// prettier-ignore
export const STATE_PROPERTIES_LIST = [
  'play',
  'scrubbar',
  'scrubThumb',
  'rewind15s',
  'forward15s',
  'liveOnAir',
  'jumpToLive',
  'showGuide',
  'skipCounter',
  // skipNext,
  // skipBack,
  // would like to change out next two for the above two just waiting to see if these are still not going to be used with Audacy 2.0
  'previous',
  'next',
  'thumbUp',
  'thumbDown',
  'onDemand',
  'share'
];

// controls affected by station types that should be shown from the list above
export const DIGITAL_PROPERTIES_TO_SHOW = ['play', 'next', 'skipCounter', 'thumbUp', 'thumbDown', 'scrubbar'];
export const PODCAST_PROPERTIES_TO_SHOW = ['play', 'scrubbar', 'scrubThumb', 'rewind15s', 'forward15s', 'share', 'onDemand'];
export const LIVE_PROPERTIES_TO_SHOW = ['play'];
export const REWIND_PROPERTIES_TO_SHOW = [
  'play',
  'liveOnAir',
  'jumpToLive',
  'showGuide',
  'share'
];

// state codes
// prettier-ignore
export const STATE_MODIFIERS = {
  /******************************************************************************************************************
   * DIGITAL SHOWS
   *   Preset to Shown and Enabled: play/pause, scrubbar and NO thumb
   *   Hide: either play or stop
   */

  // 0
  DIGITAL_ADPAUSE: {
    play: { iconType: 'play' },
    next: { disabled: true },
    skipCounter: { disabled: true },
    thumbUp: { disabled: true },
    thumbDown: { disabled: true },
    scrubbar: { clickable: false }
  },

  DIGITAL_ADPLAY: {
    play: { iconType: 'pause' },
    next: { disabled: true },
    skipCounter: { disabled: true },
    thumbUp: { disabled: true },
    thumbDown: { disabled: true },
    scrubbar: { clickable: false }
  },

  DIGITAL_EXAD: {
    play: { iconType: 'play', disabled: true },
    next: { disabled: true }, // <-- can you skip the song during a Preroll Ad?
    skipCounter: { disabled: true },
    thumbUp: { disabled: true },
    thumbDown: { disabled: true },
    scrubbar: { clickable: false }
  },

  DIGITAL_PAUSE: {
    play: { iconType: 'play' },
    scrubbar: { clickable: false }
  },

  DIGITAL_PLAY: {
    play: { iconType: 'pause' },
    scrubbar: { clickable: false }
  },

  /******************************************************************************************************************
   *    Live -  always at the live point of a station with no rewind capabilities at all. Like a music station or
   *            sports/new with no rewind shows or the station is broken
   */


  LIVE_AD: {
    play: { iconType: 'stop' }
  },
  LIVE_ADSTOP: {
    play: { iconType: 'play' }
  },

  LIVE_EXAD: {
    play: { iconType: 'play', disabled: true }    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
  },

  LIVE_PLAY: {
    play: { iconType: 'stop' }
  },

  LIVE_STOP: {
    play: { iconType: 'play' }
  },

  /******************************************************************************************************************
   * REWIND SHOWS that are NON-REPLAYABLE and AT LIVE POINT
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these: both 15sec buttons, share
   *   Make not Clickable or Draggable: scrubbar and thumb
   *   Hide: either play or stop, jumpToLive
   */

  // 9 - EX ads are only seen on Entercom owned Podcast
  PODCAST_EXAD: {
    play: { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s: { disabled: true },
    rewind15s: { disabled: true },
    share: { disabled: true },
    onDemand: { disabled: true }
  },

  PODCAST_PAUSE: {
    play: { iconType: 'play' }
  },

  PODCAST_PLAY: {
    play: { iconType: 'pause' }
  },

  /******************************************************************************************************************
   * REWIND SHOWS that are NON-REPLAYABLE and AT LIVE POINT
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these: both 15sec buttons, share
   *   Make not Clickable or Draggable: scrubbar and thumb
   *   Hide: either play or stop, jumpToLive
   */

  // 12
  REWIND_NOREPLAY_NONE_AD: {
    play: { iconType: 'stop' },
    rewind15s: { disabled: true },
    forward15s: { disabled: true },
    scrubbar: { clickable: false },
    scrubThumb: { draggable: false },
    share: { disabled: true },
    jumpToLive: { show: false }
  },

  REWIND_NOREPLAY_NONE_ADSTOP: {
    play: { iconType: 'play' },
    rewind15s: { disabled: true },
    forward15s: { disabled: true },
    scrubbar: { clickable: false },
    scrubThumb: { draggable: false },
    share: { disabled: true },
    jumpToLive: { show: false }
  },

  REWIND_NOREPLAY_NONE_EXAD: {
    play: { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    rewind15s: { disabled: true },
    scrubbar: { clickable: false },
    scrubThumb: { draggable: false },
    share: { disabled: true },
    jumpToLive: { show: false }
  },

  REWIND_NOREPLAY_NONE_PLAY: {
    play: { iconType: 'stop' },
    rewind15s: { disabled: true },
    forward15s: { disabled: true },
    scrubbar: { clickable: false },
    scrubThumb: { draggable: false },
    share: { disabled: true },
    jumpToLive: { show: false }
  },

  REWIND_NOREPLAY_NONE_STOP: {
    play: { iconType: 'play' },
    rewind15s: { disabled: true },
    forward15s: { disabled: true },
    scrubbar: { clickable: false },
    scrubThumb: { draggable: false },
    share: { disabled: true },
    jumpToLive: { show: false }
  },


  /******************************************************************************************************************
   * REWIND SHOWS that are NON-REPLAYABLE and AT LIVE POINT
   */

  // 17
  REWIND_NOREPLAY_PREV_AD: {
    play:         { iconType: 'stop' },
    rewind15s:    { disabled: true },
    forward15s:   { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_NOREPLAY_PREV_ADSTOP: {
    play:         { iconType: 'play' },
    rewind15s:    { disabled: true },
    forward15s:   { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_NOREPLAY_PREV_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    rewind15s:    { disabled: true },
    forward15s:   { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_NOREPLAY_PREV_PLAY: {
    play:         { iconType: 'stop' },
    rewind15s:    { disabled: true },
    forward15s:   { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_NOREPLAY_PREV_STOP: {
    play:         { iconType: 'play' },
    rewind15s:    { disabled: true },
    forward15s:   { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    jumpToLive:   { show: false }
  },

  /******************************************************************************************************************
   * REWIND SHOWS that are REPLAYABLE and AT LIVE POINT
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these: forward15ec
   *   Make not Clicable or Draggable:
   *   Hide: either play or pause
   * !! should we be able to scrub during a preroll add?  What about AAS ads or ads of any type?
   */

  // 21
  REWIND_REPLAY_NONE_AD: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_NONE_ADPAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_NONE_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_NONE_PAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_NONE_PLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

    /******************************************************************************************************************
   * REWIND SHOWS that are NON-REPLAYABLE and AT LIVE POINT
   */

  // 27
  REWIND_REPLAY_PREV_AD: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_PREV_ADPAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_PREV_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_PREV_PAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  REWIND_REPLAY_PREV_PLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    jumpToLive:   { show: false }
  },

  /******************************************************************************************************************
   * REWIND SHOWS that are REPLAYABLE and NOT AT LIVE POINT (can be a live shows too)
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these:
   *   Make not Clicable or Draggable:
   *   Hide: either play or pause
   */

  // 32
  REWOUND_BOTH_ADPAUSE: {
    play:         { iconType: 'play'},
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_BOTH_ADPLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_BOTH_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_BOTH_PAUSE: {
    play:         { iconType: 'play' },
    liveOnAir:    { show: false }
  },

  REWOUND_BOTH_PLAY: {
    play:         { iconType: 'pause' },
    liveOnAir:    { show: false }
  },

  /**DUPLICATE OF REWOUND_BOTH_... If not used in any new changes, they could potentially be removed        ~liz 4-21
   * ****************************************************************************************************************
   * REWIND SHOWS that are REPLAYABLE and NOT AT LIVE POINT (can be a live shows too)
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these:
   *   Make not Clicable or Draggable:
   *   Hide: either play or pause
   */

  // 37
  REWOUND_NEXT_ADPAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NEXT_ADPLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NEXT_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NEXT_PAUSE: {
    play:         { iconType: 'play' },
    liveOnAir:    { show: false }
  },

  REWOUND_NEXT_PLAY: {
    play:         { iconType: 'pause' },
    liveOnAir:    { show: false }
  },

  /**DUPLICATE OF REWOUND_BOTH_... If not used in any new changes, they could potentially be removed        ~liz 4-21
   * ****************************************************************************************************************
   * REWIND SHOWS that are REPLAYABLE and NOT AT LIVE POINT (can be a live shows too)
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these:
   *   Make not Clicable or Draggable:
   *   Hide: either play or pause
   */

  // 42
  REWOUND_NONE_ADPAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NONE_ADPLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NONE_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_NONE_PAUSE: {
    play:         { iconType: 'play' },
    liveOnAir:    { show: false }
  },

  REWOUND_NONE_PLAY: {
    play:         { iconType: 'pause' },
    liveOnAir:    { show: false }
  },

  /**DUPLICATE OF REWOUND_BOTH_... If not used in any new changes, they could potentially be removed        ~liz 4-21
   * ****************************************************************************************************************
   * REWIND SHOWS that are REPLAYABLE and NOT AT LIVE POINT (can be a live shows too)
   *   Preset to Shown and Enabled: play/stop, both 15sec, scrubbar and thumb, show guide, share, liveOnAir, jumpToLive
   *   Disable these:
   *   Make not Clicable or Draggable:
   *   Hide: either play or pause
   */

  // 47
  REWOUND_PREV_ADPAUSE: {
    play:         { iconType: 'play' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_PREV_ADPLAY: {
    play:         { iconType: 'pause' },
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    scrubbar:     { clickable: false },
    scrubThumb:   { draggable: false },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_PREV_EXAD: {
    play:         { iconType: 'play', disabled: true },    // <-- this could be play or pause (play for embed & non-auto play streams or pause for auto-play)
    forward15s:   { disabled: true },
    rewind15s:    { disabled: true },
    share:        { disabled: true },
    liveOnAir:    { show: false }
  },

  REWOUND_PREV_PAUSE: {
    play:         { iconType: 'play' },
    liveOnAir:    { show: false }
  },

  //51
  REWOUND_PREV_PLAY: {
    play:         { iconType: 'pause' },
    liveOnAir:    { show: false }
  }
};
