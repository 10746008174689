<template>
  <div id="muteButtonControl" v-if="!isMobile">
    <div id="muteButton" :class="iconClass" @click="muteClick"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MuteControl',

  computed: {
    ...mapGetters({
      fract: 'volume/getFract',
      isMuted: 'volume/getIsMuted',
      isMobile: 'isMobile'
    }),

    iconClass: function() {
      const volume = Math.trunc((this.fract + 0.00001) * 100);
      if (volume === 0 || this.isMuted === true) return 'muted';
      else if (volume < 50) return 'lowVol';
      else return 'highVol';
    }
  },

  mounted() {
    this.presetMute();
  },

  methods: {
    ...mapActions({
      requestMuteChange: 'volume/requestMuteChange',
      presetMute: 'volume/presetMute'
    }),

    muteClick() {
      this.requestMuteChange(!this.isMuted);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

#muteButtonControl {
  #muteButton {
    background: $svg_volume_mute_outline_white no-repeat left 0px;
    height: 26px;
    margin: 0 0 0 9px;

    &.highVol {
      background: $svg_volume_high_outline_white no-repeat left 0px;
    }
    &.lowVol {
      background: $svg_volume_low_outline_white no-repeat left 0px;
    }
    &.muted {
      background: $svg_volume_mute_outline_white no-repeat left 0px;
    }
  }
}

.audacyPlayer {
  #muteButtonControl {
    #muteButton {
      background-size: auto 26px;
      height: 26px;
      margin: 0 0 0 9px;

      &.highVol {
        background-size: auto 26px;
      }
      &.lowVol {
        background-size: auto 26px;
      }
      &.muted {
        background-size: auto 26px;
      }
    }
  }
}

.embeddedPlayer {
  #muteButtonControl {
    #muteButton {
      background-size: auto 15px;
      height: 15px;
      margin: 0 0 0 9px;

      &.highVol {
        background-size: auto 15px;
      }
      &.lowVol {
        background-size: auto 15px;
      }
      &.muted {
        background-size: auto 15px;
      }
    }
  }
}
</style>
