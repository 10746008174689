<template>
  <div class="playBtnContainer" v-on="$listeners">
    <button :class="playButtonClass" />
    <div :class="spinnerButtonClass" />
  </div>
</template>

<script>
export default {
  name: 'BaseButtonsPlay',
  inheritAttrs: false,
  props: {
    playButtonClass: {
      type: String
    },
    spinnerButtonClass: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../../assets/_variables2.scss';
.controlsGrp {
  button {
    vertical-align: top;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    pointer-events: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;

    &:hover {
      opacity: $hoverOpacity-7;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &.disabled:hover {
      opacity: inherit;
    }

    &:focus {
      outline: 0;
    }
  }
}

.embeddedPlayer {
  .playBtnContainer {
    width: 44px;
    height: 44px;

    display: inline-block;

    button {
      &.play {
        background-image: $svg_play_solid_grey;
        &.disabled {
          background-image: $svg_pause_solid_grey;
        }
      }

      &.stop {
        background-image: $svg_stop_solid_grey;
        &.disabled {
          background-image: $svg_stop_solid_grey;
        }
      }

      &.pause {
        background-image: $svg_pause_solid_grey;
        &.disabled {
          background-image: $svg_pause_solid_grey;
        }
      }
    }
  }

  &.rewind,
  &.rewound,
  &.digital,
  &.podcast {

    #row2 {
      .playBtnContainer {
        display: inline-block;
        position: relative;
        width: 12px;
        height: 16px;
        margin: 0 10px;

        button {
          &.play {
            background-image: $svg_play_outline_white;
            &.disabled {
              background-image: $svg_play_outline_grey;
            }
          }

          &.stop {
            background-image: $svg_stop_outline_white;
            &.disabled {
              background-image: $svg_stop_outline_grey;
            }
          }

          &.pause {
            background-image: $svg_pause_outline_white;
            &.disabled {
              background-image: $svg_pause_outline_grey;
            }
          }
        }
      }
    }
  }

}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .embeddedPlayer {
    &.rewind,
    &.rewound,
    &.podcast {
      #row2 {
        .playBtnContainer {
          margin: 0 5px;
        }
      }
    }
  }
}

//__________ Loading Spinner ____________________
@keyframes loadingSpinner {
  100% {
    transform: rotate(360deg);
  }
}

.loadingSpinner {
  display: none;
}

.streamLoading {
  #playButton {
    background: $logo_a_solid_white no-repeat center center;
    background-size: 17px 13.3px; // these dimensions are an approximation - original svg is 64x51 px
  }

  .loadingSpinner {
    display: block;
    position: absolute;
    top: -1px;
    width: 32px;
    height: 32px;
    background: $svg_R_spinner_icon no-repeat left center;
    background-size: contain;
    animation: loadingSpinner 1s infinite linear;
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .embeddedPlayer {


    &.rewind,
    &.rewound,
    &.podcast {
      #row2 {
        .playBtnContainer {
          margin: 0 6px;
        }
      }
    }
  }
}
</style>
