<template>
  <img
    :id="id"
    :class="classes"
    :src="src"
    :style="styles"
    :width="width"
    @error="useFallbackImage"
    :height="height"
    :title="title"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'baseImage',

  inheritAttrs: false,

  props: {
    id: {
      type: String
    },
    classes: {
      type: String
    },
    src: {
      type: String
    },
    fallbackSrc: {
      type: String
    },
    styles: {
      type: Object
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    title: {
      type: String
    },
    alt: {
      type: String,
      default: 'Image'
    }
  },

  computed: {
    ...mapGetters({
      stationPosterImageUrl: 'metadata/getStationPosterImageUrl'
    }),
  },

  methods: {
    useFallbackImage(event) {
      event.target.src = this.stationPosterImageUrl || 'https://images.radio.com/logos/RDC.jpg?width=108';
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../../assets/_variables2.scss';

#poster {
  width: 100%;
  height: 100%;
  border: 1px solid $clr_dusk_dark;
}

.embeddedPlayer {
  #poster {
    border: 1px solid $clr_dusk_dark;
  }
}
</style>
