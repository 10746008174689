<template>
  <div id="sliderWrapper" class="slider sliderWrapper">
    <base-rails
      rail-id="scrubbar-railBase"
      rail-class="scrubbar-rail"
      base-rail-id="scrubbar-railBase"
      :rail-fract="fullWidth"
      :rail-clickable="controls.scrubbar.clickable"
    />
    <base-rails
      rail-id="scrubbar-railLoaded"
      rail-class="scrubbar-rail"
      base-rail-id="scrubbar-railBase"
      :rail-fract="liveFract"
      slide-direction="horizontal"
      v-on:rcvdFract="rcvdFract"
      :rail-clickable="controls.scrubbar.clickable"
    />
    <base-rails
      rail-id="scrubbar-railProgress"
      rail-class="scrubbar-rail"
      base-rail-id="scrubbar-railBase"
      :rail-fract="fract"
      slide-direction="horizontal"
      v-on:rcvdFract="rcvdFract"
      :rail-clickable="controls.scrubbar.clickable"
    />
    <base-thumb
      v-if="controls.scrubThumb.show"
      thumb-id="scrubbar-thumb"
      thumb-class="scrubbar-thumb"
      base-rail-id="scrubbar-railBase"
      assoc-rail-id="scrubbar-railLoaded"
      boundary-id="playerContainer"
      :thumb-fract="fract"
      :clockAtFract="clockAtFract"
      slide-direction="horizontal"
      v-on:rcvdFract="rcvdFract"
      :rail-clickable="controls.scrubbar.clickable"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseRails from './baseComponents/BaseRails';
import BaseThumb from './baseComponents/BaseThumb';

export default {
  name: 'SliderControl',

  components: {
    BaseRails,
    BaseThumb
  },

  data() {
    return {
      fullWidth: 1
    };
  },

  computed: {
    ...mapGetters({
      fract: 'scrubbar/getFract',
      liveFract: 'scrubbar/getLiveFract',
      clockAtFract: 'scrubbar/getClockAtFract',
      controls: 'stateCodes/getControls'
    })
  },

  mounted() {
    this.scrubbarRailBase = document.getElementById('scrubbar-railBase');
  },

  methods: {
    ...mapActions({
      requestFractChange: 'scrubbar/requestFractChange'
    }),

    rcvdFract(fract) {
      this.requestFractChange({ fraction: fract });
    }
  }
};
</script>

<style lang="scss" scoped>
.sliderWrapper {
  height: 16px;
}

.scrubbar {
  position: relative;
  display: inline-block;
  margin: 4px auto 0;
  vertical-align: top;
}

</style>
