import store from '../store/index.js';
// import { ClientServices, HtmlPlayer, GoogleAnalytics, AnalyticsServices } from '../../../radio-client-services/build/lib/libServices.js';
import { ClientServices, HtmlPlayer, GoogleAnalytics, AnalyticsServices } from 'clientServices';
import { isMobileDevice } from '../utils/utils';

const API_URLS = {
  production: 'https://api.radio.com/',
  stage: 'https://api-stg.radio.com/'
};

const SUPPORTED_TYPES = ['station', 'exclusive-station'];

export default class ExchangeService {
  constructor() {
    this.adServices;
    this.analyticsServices;
    this.audioServices;
    this.clientServices;
    this.dataServices;
    this.isInitialized;
  }

  // I believe that Unity calls to initialize, if so this must be here anyways.
  initialize(options) {
    if (!this.isInitialized) {
      this.isInitialized = true;
      store.dispatch('setIsMobile', isMobileDevice());
      options = { rewindEnabled: true }; // Embedded ForceLiveOnly = t/f
      this.instantiatePlayer(options);
    }
  }

  instantiatePlayer(options) {
    this.instantiateClientServices(options, this.playerEvents);
    this.getOtherServices();
    this.logVersions();
  }

  /*________________________________________________________________________________________
      EVENTS - Client Services emit these events for Vue.js to respond to
   _________________________________________________________________________________________ */
  playerEvents(event) {
    //console.log('@@@@@@@@@@ event:', event.type, event);

    if (event) {
      switch (event.type) {
        case 'loading':
        case 'stalled':
          store.dispatch('stateCodes/updateStreamState', event);
          break;

        case 'newStream':
          store.dispatch('notifications/clearErrors', event);
          store.dispatch('stateCodes/updateStreamState', event);
          break;

        case 'state':
          store.dispatch('notifications/clearErrors', event);
          store.dispatch('stateCodes/updateStateCodes', event);
          store.dispatch('updateState', event);
          break;

        case 'metadata':
          store.dispatch('metadata/updateMetadata', event);
          break;

        case 'error':
          console.log('@@@@@@@@@@ error event:', event);
          store.dispatch('notifications/addError', event);
          break;

        case 'volume':
          store.dispatch('volume/updateVolume', event);
          break;

        case 'isMuted':
          store.dispatch('volume/updateMute', event);
          break;

        case 'update':
          store.dispatch('notifications/clearErrors', event);  // if we're getting timeUpdate events from CS, that means the audio is playing and we shouldn't be showing an error message to the user
          store.dispatch('scrubbar/updateUpdate', event);
          break;

        case 'adBreaks':
          store.dispatch('scrubbar/updateAdBreaks', event);
          break;

        default:
        // console.log('@@@@@@@@@@ Default: ', event);
      }
    }
  }

  /*________________________________________________________________________________________
     FOR EMBEDDED URLS: Launching A Station or Podcast
   _________________________________________________________________________________________ */
  /**
   * @param {num} stationData
   * @param {num} currentStationIndex
   * @param {string} slug
   * @param {string} type - ''
   */

  launchPlayerEmbedded(streamId, slug, callsign, type, time) {

    store.dispatch('updatePlayerType', type);
    this.instantiatePlayer({ rewindEnabled: type === 'rewind-show' });

    if (SUPPORTED_TYPES.indexOf(type) === -1) {
      console.log(`Unsupported embed type ${type}`);
      store.dispatch('notifications/addError', { errorType: 'NO_METADATA' });
      return;
    }

    this.launchStationEmbedded(streamId, slug, callsign, time);
  }

  launchPodcastsOrClipsEmbedded(podcastId, episodeId, slug, type) {
    this.instantiatePlayer();
    store.dispatch('updatePlayerType', type);
    store.dispatch('updateSlug', slug);

    // is toString because the devUi is using a number.
    // for podcast embed, we'll be using the slug for lookup
    episodeId = episodeId.toString();
    const self = this;

    const onGotPodcast = function(podcast) {
      const onGotEpisode = function(episodeList) {
        const episode = episodeList.getDataObject(0);
        self.audioServices.play(episode);
      };

      podcast
        .getEpisodeList(self.dataServices)
        .then(onGotEpisode)
        .catch(e => {
          console.error(e);
          store.dispatch('notifications/addError', { errorObj: e });
        });
    };

    self.dataServices
      .getPodcast(episodeId)
      .then(onGotPodcast)
      .catch(e => {
        console.error(e);
        store.dispatch('notifications/addError', { errorObj: e });
      });
  }

  getStationLookupFunction (streamId, slug, callsign) {

    if (slug) {
      return this.dataServices.getStationBySiteSlug;
    } else if (callsign) {
      return this.dataServices.getStationByCallsign;
    } else {
      return this.dataServices.getStation;
    }
  }

  /**
   * Launching Station
   * @param {num} streamId
   * @param {string} slug
   * @param {string} callsign
   * @param {string} number
   */

  async launchStationEmbedded (streamId, slug, callsign, time) {

    const stationLookup = slug || callsign || streamId;
    const lookupMethod = this.getStationLookupFunction(streamId, slug, callsign);

    const station = await(lookupMethod.call(this.dataServices, stationLookup));

    if (!station) {
      console.error('Unable to get the station data.');
      store.dispatch('notifications/addError', { errorType: 'NO_METADATA' });
      return;
    }

    store.dispatch('setStation', station);
    store.dispatch('updateSlug', station.getSiteSlug());
    store.dispatch('metadata/updateStationPosterImageUrl', station.getImage());
    store.dispatch('metadata/updateMetadata', { station: station.getName()}); // this allows the station name to show up upon load and before hitting the play button for exclusive stations

    if (time || station.type === "Interactive") {
      store.dispatch('setInteractive', true);
      this.launchRewindStationEmbedded(station, time);
    } else {
      this.launchLiveStationEmbedded(station);
    }
  }

  launchLiveStationEmbedded (station) {
    if (station) {
      // rewind stations don't send metadata right away
      try {
        this.audioServices.play(station, null, null, false);
      } catch (e) {
        console.error(e); // ! create a function to receive the error and pass the error and object.   New function can then call console.log and the notifiction.
        store.dispatch('notifications/addError', { errorObj: e });
      }
    } else {
      console.error('Unable to get the station data.');
      store.dispatch('notifications/addError', { errorType: 'NO_METADATA' });
    }
  }

  async launchRewindStationEmbedded (station, time = 0) {
    let playLive = time ? false : true;
    time = time || Date.now() / 1000;
    let timeMillis = time * 1000;

    let schedule = await(station.getSchedule(timeMillis));

    if (playLive) {
      store.dispatch('setRewindTime', timeMillis);
      store.dispatch('setShouldPlayFromEpochTime', true);
    }

    if (schedule) {
      store.dispatch('setSchedule', schedule);
      this.setMetadataFromStation (station, schedule);
    } else {
      console.error(`Error: couldn't find schedule for station ${station.name} at epoch time ${time}`);
      store.dispatch('notifications/addError', {});
    }
    try {
      this.audioServices.play(station, schedule, timeMillis, false); // the last param is to prevent autoplay on page load
    } catch (e) {
      console.error(e);
      if (e.error !== StreamManager.Error.PLAYER_ABORT) {
       store.dispatch('notifications/addError', { errorObj: e });
      }
    }
  }

  setMetadataFromStation (station, schedule = null) {
    const stationMetadata = {
     // ! do not set anything in store from here
     type: 'metadata',
     image: station.image,
     station: station.name,
     category: station.category,
     songOrShow: schedule ? schedule.name : ''
    };
    store.dispatch('metadata/updateMetadata', stationMetadata);
  }

  /*_________________________________________________________________________________________________________
      Vue.js Store and Components calls these functions to/from Client Services' Classes
   _______________________________________________________________________________________________________ */
  /*
   * VOLUME SET & GET
   * @param {obj} payload
   * payload.fraction if this is a number then set the volume, once set return retreived value.
   * payload.default is the state.volume.defalt number.
   */
  setAudioVolume(payload) {
    const fraction = payload.fraction;
    const isVolumeInRange = typeof fraction === 'number' && fraction >= 0 && fraction <= 1;
    const volume = isVolumeInRange ? fraction : payload.default;

    this.audioServices.setVolume(volume);

    // this is used so that going to/from 0, GA will track as mute/unmute as well. Otherwise, is unneeded.
    if (volume === 0) this.setAudioMute(true);
    else if (this.audioServices.getMute()) this.setAudioMute(false);
  }

  setAudioMute(payload) {
    this.audioServices.setMute(payload);
  }

  getAudioVolume() {
    return this.audioServices.getVolume();
  }

  getAudioMute() {
    return this.audioServices.getMute();
  }

  /*
   * PLAYER CONTROL FUNCTION CALLS
   * @param {string} controlClicked received string is used to determine which function to call.
   * Using the parameter in the bracket notation to call a method from 'audioServices'.
   */
  updateAudioTagStreamState(controlClicked) {
    store.dispatch('notifications/clearErrors');

    switch (controlClicked) {
      case 'skipBack':
        // console.log('>>>>> >>>>>  skip back');
        this.audioServices.skip(-15);
        break;

      case 'skipForward':
        // console.log('>>>>> >>>>>  skip forward');
        this.audioServices.skip(15);
        break;

      case 'goToLive':
        // console.log('>>>>> >>>>>  goToLive');
        this.audioServices.goToLive();
        break;

      case 'next':
        // console.log('>>>>> >>>>>  next');
        this.audioServices.next();
        break;

      case 'play':
        if (store.getters.shouldPlayFromEpochTime) {
          store.dispatch('setShouldPlayFromEpochTime', false);
          this.audioServices.play(store.getters.getStation, store.getters.getSchedule, store.getters.getRewindTime);
        } else if (store.getters.isInteractive) {
          this.audioServices.goToLive(); // treat an interactive (rewind) station as a live one
        } else {
          this.audioServices.play();
        }
        break;

      default:
        // console.log('>>>>> >>>>>  this.audioServices[controlClicked]()', controlClicked);
        this.audioServices[controlClicked]();
        // console.error('Need to handle control = ' + which);
        break;
    }
  }

  /*
   * Jump to a spot in a show based of a fraction being passed in
   * @param {obj} payload
   */
  requestJumpToFraction(payload) {
    console.log('>>>>> >>>>>  setJumpToFraction(payload.fracton)', payload.fraction);
    this.audioServices.setJumpToFraction(payload.fraction);
  }

  /**
   * This fires off the event to client services to record that the open radio.com button was
   * clicked from the embedded player for analytics.
   */
  openRDCClick() {
    const self = this;
    return self.analyticsServices.clickToRdc.call(self.analyticsServices, AnalyticsServices.RdcClickSource.RDC_BUTTON);
  }

  simulateError(error = 1) {
    this.playerEvents({
      type: 'error',
      error: error
    });
  }

  instantiateClientServices(options, onPlayerEvent) {
    console.log('instantiating client services with options', options);
    const htmlPlayer = new HtmlPlayer();
    const params = new URLSearchParams(window.location.search);
    const apiUrl = params && params.get('api') === 'stage' ? API_URLS['stage'] : API_URLS['production'];

    const servicesConfig = {
      adServices: {
        aasRoot: 'https://aas.radio.com/ad/vast',
        platform: 'web'
      },
      audioServices: {
        digitalStationPlayer: htmlPlayer,
        digitalStationRoot: 'https://smartstreams.radio.com/',
        episodePlayer: htmlPlayer,
        rewindEnabled: options.rewindEnabled,
        rewindPlayer: htmlPlayer,
        livePlayer: htmlPlayer,
        eventListener: onPlayerEvent,
        webcastMetricsStr: 'DIST=CBS-embed&TGT=radiocomPlayer_embed&SRS=CBS',
        platform: 'embed',
        amperwaveSourceParameter: 'embed-player'
      },
      dataServices: {
        version: 'v1',
        radioApi: apiUrl
      },
      analyticServices: {
        google: {
          analyticsPlatform: GoogleAnalytics.Platform.DESKTOP,
          gtmContainer: 'GTM-5XL9KZ5',
          writeToServer: true
        }
      }
    };

    this.clientServices = new ClientServices(servicesConfig);
  }

  /*_________________________________________________________________________________________________________
      PRIVATE METHODS - Well private in the scense that they are only called by other funtions here :/
   _______________________________________________________________________________________________________ */
  getOtherServices() {
    this.adServices = this.clientServices.getAdServices();
    this.analyticsServices = this.clientServices.getAnalyticsServices();
    this.audioServices = this.clientServices.getAudioServices();
    this.dataServices = this.clientServices.getDataServices();
  }

  logVersions() {
    this.versionInfo = process.versionInfo || {
      packageVersion: undefined,
      buildInfo: undefined
    };

    store.dispatch('setVersionInfo', this.versionInfo);
    console.log('%cClient Services Version', 'color: Plum', this.clientServices.getVersionInfo());
    console.info('%cEmbed Player Version ', 'color: Plum', this.versionInfo);
    console.log('%c**************************************************', 'color: Plum');
  }
}
