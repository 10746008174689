<template>
  <button id="forward15Button" class="btn15s forward15s" @click="openShareDialog(true)"></button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Forward15Button',

  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      stationHasPlayed: 'getStationHasPlayed'
    }),
  },

  methods: {
    ...mapActions({
      openShareDialog: 'sharing/setShowShareDialog'
    })
  }
};
</script>

<style lang="scss" scoped>
@import './../assets/_variables2.scss';

button {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 10px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
  background-size: contain;
  background-repeat: no-repeat;
  white-space: nowrap;

  &:hover {
    opacity: $hoverOpacity-7;
  }

}

button.btn15s {
  &.rewind15s {
    background: $svg_15sec_rewind_outline_white center no-repeat;
  }

  &.forward15s {
    background: $svg_15sec_forward_outline_white center no-repeat;
  }

  &.disabled {
    background: $clr_grey_med;
  }
}

</style>
