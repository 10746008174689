<template>
  <div id="playerBar" :class="playerBarClass">
    <div id="row1" class="row">

      <poster-image class="posterImageWrapper"/>
      <div class="nonPosterItems">
        <metadata-block class="metadataBlockWrapper metadataBlockWrapperTop" />
        <player-controls class="playerControlsWrapper" :stationType="this.stationType" />
        <share-button v-if="isRewindStation" />
        <div v-if="stationHasPlayed">
          <scrubbar-container v-if="controls.scrubbar.show" />
        </div>
      </div>

    </div>



    <div id="row2" class="row" :class="stationType" >

      <player-controls class="playerControlsWrapper" :stationType="this.stationType" />
      <metadata-block class="metadataBlockWrapper" />

      <div v-if="stationHasPlayed">
        <scrubbar-container v-if="controls.scrubbar.show" />
      </div>

    </div>

    <!-- These items are absolutely placed -->
    <listen-on-audacy class="listenOnAudacyBottom" v-if="!stationHasPlayed || !isExclusiveStation" />
    <div id="logoWrapper"><span class="logo"></span></div>
    <volume-mute-controls v-if="showVolumeControl && noErrors" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlayerControls from './PlayerControls';
import MetadataBlock from './../../components/MetadataBlock';
import ScrubbarContainer from './../../components/ScrubbarContainer';
import VolumeMuteControls from './../../components/VolumeMuteControls';
import ListenOnAudacy from './../../components/ListenOnAudacy';
import ShareButton from './../../components/ShareButton';
import PosterImage from './../../components/PosterImage';
import { isMobileIOSDevice } from './../../utils/utils'

export default {
  name: 'LayoutPlayerBar',

  components: {
    MetadataBlock,
    PlayerControls,
    VolumeMuteControls,
    ScrubbarContainer,
    ListenOnAudacy,
    ShareButton,
    PosterImage
  },
  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      slug: 'getSlug',
      stationType: 'stateCodes/getStationType',
      errors: 'notifications/getForbiddenErrors',
      stationHasPlayed: 'getStationHasPlayed',
    }),

    isInteractive() {
      // using this to prevent row 2 from showing onload, but doesn't not seem to have an effect.
      // could put `stationType !== 'live'` back to repalce this funtion call. Liz 4/21
      if (this.stationType === 'digital' && isMobileIOSDevice()) return false;

      return this.stationType === 'rewind' || this.stationType === 'rewound' || this.stationType === 'podcast' || this.stationType === 'digital'
        ? true
        : false;
    },

    websiteUrl() {
      const marconiTrialSlugs = ['alt923-marconi', 'densand']; //TODO: this is for the marconi trial  - remove later?
      return this.slug && marconiTrialSlugs.indexOf(this.slug) === -1 ? `https://audacy.com/${this.slug}/listen` : `https://audacy.com`;
    },

    noErrors() {
      return !this.errors || this.errors.length === 0;
    },

    isRewindStation() {
      return false; //this.stationType === 'rewind' || this.stationType === 'rewound';
    },

    isExclusiveStation() {
      return this.stationType === 'digital';
    },

    playerBarClass() {
      return this.stationHasPlayed ? 'stationHasPlayed' : '';
    },

    showVolumeControl() {
      return false;
    }
  },

  methods: {
    ...mapActions({
      openShareDialog: 'sharing/setShowShareDialog',
      btnClicked: 'stateCodes/controlButtonClicked'
    }),

    showShareButton() {
      if (this.isRewindStation()) {
        return false;
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/_variables2.scss';

#playerBar {
  z-index: 10;
  position: relative;

  height: $player_height;

  .row {
    position: relative;
  }

  #row1 {

    .nonPosterItems {
      padding-top: 15px;
      display: inline-block;
      width: calc(100vw - 225px);

      height: 205px;
      position: relative;

      .digital & {
        .listenOnAudacy {
          position: relative;
          top: 0;
          left: 0;
          margin-top: 15px;

          .stationHasPlayed & {
            position: absolute;
            bottom: 0;
            left: 0;
          }

        }
        .scrubbarContainer {
          position: absolute;
          left: 0;
          bottom: 1px;
        }
      }

    }

  }


  #row2 {
    display: none;
    height: 38px;
    padding: 0;
  }

  .posterImageWrapper {
    display: inline-block;
    margin: 15px 20px 8px 15px;
  }

  .playBtnContainer {

  }

  #logoWrapper {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .logo {
    background: $logo_a_solid_white no-repeat center center;
    width: 20px;
    height: 16px;
    background-size: cover;
    margin-right: 2px;
    display: inline-block;
  }

  .listenOnAudacyBottom {
    position: absolute;
    bottom:15px;
    left: 220px;
  }


}

@media only screen and (min-width: 436px) and (max-width: 701px) {
  #playerBar {
    #row1 {

      height: 182px;

       .listenOnAudacy {
         display: none;
       }

       .nonPosterItems {
         width: calc(100vw - 201px);
       }

       .scrubbarContainer {
         display: none;
       }



    }
    #row2 {
       display: block;

       .metadataBlockWrapper, .playerControlsWrapper {
         display: none;
       }

       .listenOnAudacy {
         left: 15px;
       }

    }

    .posterImageWrapper {
      margin-right: 15px;
    }

    .listenOnAudacyBottom {
      left: 15px;
    }

  }


}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  #playerBar {

    #row1 {
      height: 104px;
      .metadataBlockWrapper, .listenOnAudacy {
        display: none;
      }

      .playerControlsWrapper {
        display: table-cell;
        vertical-align: middle;
        height: 70px;
      }

      .nonPosterItems {
        width: calc(100vw - 115px);
      }

      .scrubbarContainer {
        display: none;
      }

    }


    #row2 {
     display: block;
     height: 110px;
     .metadataBlockWrapper, .listenOnAudacy {
       display: block;
     }


     .playerControlsWrapper {
       display: none;
     }

    }

    .listenOnAudacy {
      bottom: 20px;
      left: 15px;
      font-size: 12px;

      .digital & {
        font-size: 14px;
      }
    }

    .posterImageWrapper {
      margin-right: 15px;

      .stationHasPlayed & {
        margin-right: 10px;
      }
    }


  }


}
</style>
