<template>
  <base-buttons-others id="listenButton" class="listenBtn" @click="listenButtonClicked()"><span class="playTriangle"></span>Listen</base-buttons-others>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButtonsOthers from './baseComponents/BaseButtonsOthers.vue';
export default {
  name: 'ListenButton',

  components: {
    BaseButtonsOthers
  },

  methods: {
    ...mapActions({
      listenButtonClicked: 'stateCodes/listenButtonClicked'
    })
  }
};
</script>

<style lang="scss" scoped>


</style>
