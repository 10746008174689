export const namespaced = true;
import { setSessionStorageItem, getSessionStorageItem } from '../../utils/storage.js';

// ouside of index.js functions :)
function setVolumeStorage(payload) {
  setSessionStorageItem(payload);
  getVolumeStorage(payload);
}

function getVolumeStorage(payload) {
  return getSessionStorageItem(payload);
}

export const state = {
  volume: {
    fraction: 0,
    isMuted: false,
    muteDefault: false,
    volDefault: 0.5
  }
};

export const mutations = {
  // ***** VOLUME *****
  SET_VOLUME(state, fraction) {
    state.volume.fraction = fraction;
  },

  SET_MUTE(state, payload) {
    state.volume.isMuted = payload.val;
  }
};

export const actions = {
  // ***** VOLUME *****
  updateVolume({ commit }, payload) {
    setVolumeStorage({ key: payload.type, value: payload.val });
    commit('SET_VOLUME', payload.val);
  },

  updateMute({ commit }, payload) {
    setVolumeStorage({ key: payload.type, value: payload.val });
    commit('SET_MUTE', payload);
  },

  requestVolumeChange({ state }, payload) {
    // pass a default value, just in case
    payload.default = state.volume.volDefault;
    ExchangeService.setAudioVolume(payload);
  },

  // eslint-disable-next-line no-unused-vars
  requestMuteChange({ state }, payload) {
    ExchangeService.setAudioMute(payload);
  },

  presetVolume({ state, dispatch }) {
    // Volume
    let fraction;
    const storageVolume = getVolumeStorage({ key: 'volume' });

    if (storageVolume !== 'undefined' && storageVolume !== null) fraction = storageVolume * 1;
    if (isNaN(fraction)) fraction = state.volume.volDefault;
    dispatch('requestVolumeChange', { fraction: fraction });
  },

  presetMute({ state, dispatch }) {
    // Mute
    let isMuted;
    const storageIsMuted = getVolumeStorage({ key: 'isMuted' });
    // TODO probably needs a refactor :/ Liz
    if (storageIsMuted === 'true' || storageIsMuted === 'false') {
      isMuted = storageIsMuted == 'true' ? true : false;
    } else {
      isMuted = state.volume.muteDefault;
    }

    dispatch('requestMuteChange', isMuted);
  }
};

export const getters = {
  getFract: state => {
    if (state.volume.isMuted) return 0;
    return state.volume.fraction;
  },

  getIsMuted: state => {
    return state.volume.isMuted;
  }
};
