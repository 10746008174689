<template>
  <div class="controlsGrp">
    <listen-button v-if="!stationHasPlayed" />
    <span v-if="stationHasPlayed">
      <back-15-button  v-if="showRewindControls && controls.rewind15s.show" @click="btnClicked('skipBack')" />
      <play-icon-control />
      <skip-button v-if="controls.next.show" />
      <forward-15-button  v-if="showRewindControls && controls.forward15s.show" @click="btnClicked('skipForward')" />
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ListenButton from './../../components/ListenButton';
import SkipButton from './../../components/SkipButton';
import Forward15Button from './../../components/Forward15Button';
import Back15Button from './../../components/Back15Button';
import PlayIconControl from './../../components/PlayIconControl';


export default {
  name: 'PlayerControls',

  components: {
    ListenButton,
    PlayIconControl,
    SkipButton,
    Forward15Button,
    Back15Button
  },

  data: function() {
    return {
      previousSkipsAvailable: null
    }
  },

  props: {
    stationType: {
      type: String
    }
  },

  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      stationHasPlayed: 'getStationHasPlayed'
    }),

    skipNumber: function() {
      if (this.skipsAvailable || this.skipsAvailable === 0) return this.skipsAvailable;
      return 6;
    },

    isSkippableClass: function() {
      return this.skipsAvailable && this.skipsAvailable !== 0 ? true : false;
    },

    isPural: function() {
      return this.skipsAvailable !== 1 ? 's' : '';
    },

    showRewindControls: function() {
      return false;
    }

  },

  methods: {
    ...mapActions({
      btnClicked: 'stateCodes/controlButtonClicked'
    }),
    checkToEmit: function(param, event) {
      console.log('chekcing to emit');
      if (this.skipsAvailable && this.skipsAvailable !== 0 && this.skipsAvailable !== this.previousSkipsAvailable) {
        this.btnClicked(param);
        this.previousSkipsAvailable = this.skipsAvailable;
      } else {
        event.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.embeddedPlayer {
  .controlsGrp {
    display: inline-block;
    // width: 86px;
    height: 16px;
    vertical-align: top;

    > div {
      display: inline-block;
    }

    .temp {
      display: inline-block;
      width: 30px;
      border: 1px solid pink;
      font-size: 8px;
      text-align: center;
      vertical-align: top;
    }
  }

  &.digital {
    .controlsGrp {
      // width: 110px;
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .embeddedPlayer {
    &.digital {
      .controlsGrp {
      //   width: 98px;
      }
    }

    &.rewound,
    &.rewind,
    &.podcast {
      .controlsGrp {
        // width: 76px;
      }
    }
  }
}
</style>
