<template>
  <a v-if="!isExclusiveStation || !stationHasPlayed" :href="listenOnAudacyHref" target="_blank" class="listenOnAudacy">{{ this.linkText }}</a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ListenOnAudacy',

  computed: {
    ...mapGetters({
      websiteHref: 'getWebsiteHref',
      stationHasPlayed: 'getStationHasPlayed',
      streamCategory: 'stateCodes/getStationType'
    }),

    linkText: function() {
      if (this.isExclusiveStation) {
        return this.stationHasPlayed ? 'See More Exclusive Stations ›' : 'Listen on Audacy ›';
      } else {
        return this.stationHasPlayed ? 'See More Stations ›' : 'Listen on Audacy ›';
      }

    },

    isExclusiveStation: function() {
      return this.streamCategory === 'digital';
    },

    listenOnAudacyHref: function () {

      if (this.isExclusiveStation) {
        return this.stationHasPlayed ? 'https://audacy.com/exclusive-stations' : this.websiteHref;
      } else {
        return this.stationHasPlayed ? 'https://audacy.com/stations' : this.websiteHref;
      }
    }

  },

  methods: {
    openSdp() {
      ExchangeService.openRDCClick();
    }
  }
};
</script>

<style lang="scss" scoped>

  @import '../assets/_variables2.scss';

  .listenOnAudacy {
    display: block;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    text-decoration: none;
    position: absolute;
    bottom: 16px;
  }

  @media only screen and (max-width: 435px) {
    .listenOnAudacy {
      bottom: 21px;
    }
  }


</style>
