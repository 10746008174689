<template>
  <base-buttons-play
    :play-button-class="controls.play.iconType"
    spinner-button-class="loadingSpinner"
    @click="debouncedClickHandler('play')"
  ></base-buttons-play>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButtonsPlay from './baseComponents/BaseButtonsPlay.vue';
import { debounce } from '../utils/utils.js';

export default {
  name: 'PlayIconControls',

  props: {
    playButtonContainerId: {
      type: String
    }
  },

  components: {
    BaseButtonsPlay
  },

  data: function () {
    return {
      debouncedClickHandler: debounce(this.btnClicked, 500, true)
    }
  },


  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      streamState: 'stateCodes/getStreamState'
    })
  },

  methods: {
    ...mapActions({
      btnClicked: 'stateCodes/playButtonClicked'
    })
  }
};
</script>

<style lang="scss" scoped></style>
