<template>
  <div id="volumeMuteControlWrap">
    <volume-control />
    <mute-control />
  </div>
</template>

<script>
import VolumeControl from './VolumeControl';
import MuteControl from './MuteControl.vue';

export default {
  name: 'VolumeMuteControls',

  components: {
    VolumeControl,
    MuteControl
  }
};
</script>

<style lang="scss" scoped>
#volumeMuteControlWrap {
  z-index: 12;
  position: absolute;
  width: 40px;
  cursor: pointer;
}

.embeddedPlayer {
  #volumeMuteControlWrap {
    bottom: 14px;
    right: 7px;
    width: 35px;
  }
}

</style>
