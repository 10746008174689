<template>
  <div id="playerContainer" :class="layoutClass">
    <div id="embeddedPlayerContainer">
      <!-- things like the timeline or Preroll Player could be added here -->
      <player-bar v-if="!hasBlockingError" />

      <div id="errorWrapper" v-if="hasBlockingError">
        <error-message :error="blockingErrors[0]" :showIcon="true" />
      </div>

      <transition name="fade">
        <div id="shareDialog" v-if="showShareDialog">
          <share-dialog />
        </div>
      </transition>

      <div id="leftBar"></div>
      <div id="leftCenterBar"></div>
      <div id="rightCenterBar"></div>
      <div id="rightBar"></div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayerBar from './embeddedPlayer/LayoutPlayerBar.vue';
import ErrorMessage from './../components/ErrorMessage';
import ShareDialog from './../components/ShareDialog';

export default {
  name: 'LayoutEmbeddedPlayer',
  props: {
    layoutClass: {
      type: Array
    }
  },

  components: {
    PlayerBar,
    ErrorMessage,
    ShareDialog
  },

  computed: {
    ...mapGetters({
      blockingErrors: 'notifications/getBlockingErrors',
      showShareDialog: 'sharing/getShowShareDialog'
    }),
    hasBlockingError() {
      return this.blockingErrors && this.blockingErrors.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

#playerContainer {
  background: #090527;
}

#embeddedPlayerContainer {
  height: 220px;

  background: linear-gradient(135deg, #7A3BCB 0%, #1F2772 100%);
  position: relative;
  overflow: hidden;

}

#rightBar {
  position: absolute;
  top: 43px;
  right: 50px;
  width: 3.95px;
  height: 83px;
  background: rgba(255, 255, 255, 0.12);
}

#rightCenterBar {
  position: absolute;
  top: 125px;
  right: 93.38px;
  width: 3.95px;
  height: 21px;
  background: rgba(255, 255, 255, 0.12);
}

#leftCenterBar {
  position: absolute;
  top: 73.5px;
  right: 146.71px;
  width: 3.95px;
  height: 52px;
  background: rgba(255, 255, 255, 0.12);
}

#leftBar {
  position: absolute;
  top: 125px;
  right: 195.05px;
  width: 3.95px;
  height: 44px;
  background: rgba(255, 255, 255, 0.12);
}

#errorWrapper {
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 12px;
}

#shareDialog {
  background-color: #090527;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  height: 128px;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid rgba(104, 121, 224, 0.7);
  border-radius: 0 0 10px 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
