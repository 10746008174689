export const namespaced = true;
// import Vue from 'vue';
import { AudioServices } from 'clientServices';
// import { AudioServices } from '../../../../radio-client-services/build/lib/libServices.js';
import { setPropertiesToShow, initPropertiesToHidden } from '../../utils/utils';
import * as Codes from '../../constants/stateCodes';

// The fist three propertys are the only ones being used reactively by components.
export const state = {
  // reactive property used by the components to show/hide/disable control buttons
  // prettier-ignore
  currentControlsState: {
    play: { iconType: 'play' },
    scrubbar: { show: false },
    scrubThumb: { show: false },
    rewind15s: { show: false },
    forward15s: { show: false },
    liveOnAir: { show: false },
    jumpToLive: { show: false },
    showGuide: { show: false },
    previous: { show: false },
    next: { show: false },
    skipCounter: { show: false },
    thumbUp: { show: false },
    thumbDown: { show: false },
    onDemand: { show: false },
    share: { show: false }
  },

  // an additional set of states: loading and stalled and possible new stream.
  // ! Complete for loading spinner
  streamState: '',

  // partial state name from player states- 1st word - eg. REWIND/REWOWND, LIVE, PODCAST etc...
  stationTypes: {
    current: '',
    previous: ''
  },

  // a place to stage the controls state as they are being decided on.
  stagingControlsState: {},

  // state key names. eg. LIVE_STOP, REWOUND_PREV_EXAD, REWIND_NOREPLAY_NONE_STOP etc.
  playerStates: {
    incoming: '',
    current: '',
    previous: ''
  },

  // can be found in `conststants/stateCods.js` and runs from 0 - 50+
  currentStateNumber: {
    state: ''
  }
};

/**
 * MUTATIONS
 */
export const mutations = {
  SET_STATE(state, payload) {
    state.currentStateNumber.state = payload.state;
  },

  SET_PLAYER_STATES(state, payload) {
    state.playerStates.previous = state.playerStates.current;
    state.playerStates.current = payload;
  },

  SET_PLAYER_STATE_INCOMING(state, payload) {
    state.playerStates.incoming = payload;
  },

  SET_STATION_TYPES(state, payload) {
    state.stationTypes.previous = state.stationTypes.current;
    state.stationTypes.current = payload;
  },

  SET_STATES_STAGING(state, payload) {
    state.stagingControlsState = payload;
  },

  SET_CURRENT_STATE(state) {
    state.currentControlsState = { ...state.stagingControlsState };
  },

  SET_STREAM_STATE(state, streamState) {
    state.streamState = streamState;
  }
};

/**
 * ACTIONS
 */
export const actions = {
  updateStreamState({ commit }, payload) {
    // This Event will come from either be loading, stalling or new stream.
    // ! need to set up the loading spinner
    commit('SET_STREAM_STATE', payload);
  },

  /**
   * Take the incoming event payload and use it determing the state of the player.
   * @payload  = an event object incoming from 'exchangeServices.js'
   * @dispatch = updatePlayerStateName() to get AudioServices Key information and store it.
   * @dispatch = updateDefaultControls() use parsed out station type to determine base (default) state controls.
   * @dispatch = updateStation() use AudioServices Key to determine which state controls to modify per key.
   * @commit   = commit a state.stationTypes mutation
   */
  updateStateCodes({ dispatch, commit }, payload) {
    // get the incoming state name for console logs only
    // console.log('payloadonly', payload);
    const audioServiceKey = Object.keys(AudioServices.State).find(key => AudioServices.State[key] === payload.state);
    // console.log('audioServiceKey', audioServiceKey);
    commit('SET_PLAYER_STATE_INCOMING', audioServiceKey);

    // console.log('%c____________________________________________________________________________________________', 'color: Coral');
    // console.log('%ccurrent  state number: %s : %s', 'color: Coral', state.currentStateNumber.state, state.playerStates.current);
    // console.log('%cincoming state number: %s : %s', 'color: Coral', payload.state, state.playerStates.incoming);
    // console.log('%c____________________________________________________________________________________________', 'color: Coral');

    if (payload && payload.state !== state.currentStateNumber.state) {
      commit('SET_STATE', payload);
      dispatch('updatePlayerStateName', payload.state);
      dispatch('updateDefaultControls');
      dispatch('modifyState');
      commit('SET_CURRENT_STATE');
    }
  },

  /********************************************************************************************************************
   *  AUDIO SERVICES - GET KEY FROM STATE VALUE and store as state.playerState & state.stationType
   ********************************************************************************************************************/
  /**
   * Get AudioServices State Key from using AudioServices State Value
   * @payload = a number -is a value recived from the incoming state change
   * @dispatch = updateStationTypes()
   */
  updatePlayerStateName({ commit, state, dispatch }, payload) {
    // if .substring causes and error it's because 'payload' is undefined
    const audioServiceKey = Object.keys(AudioServices.State).find(key => AudioServices.State[key] === payload);
    commit('SET_PLAYER_STATES', audioServiceKey);
    if (audioServiceKey) commit('SET_STATION_TYPES', audioServiceKey.substring(0, audioServiceKey.indexOf('_')));
    dispatch('metadata/updateStreamTypeText', state.stationTypes.current, {root:true})
  },

  /********************************************************************************************************************
   *  DEFAULT CONTROLS SETTINGS Per Stream Type
   *******************************************************************************************************************/
  /**
   *
   */
  updateDefaultControls({ state, commit }) {
    let newState;
    let propertiesToShow;
    let currentState = JSON.parse(JSON.stringify(state.currentControlsState));

    const arrProperties = Codes.STATE_PROPERTIES_LIST;
    currentState = initPropertiesToHidden(currentState, arrProperties);

    switch (state.stationTypes.current) {
      case 'REWIND':
      case 'REWOUND':
        propertiesToShow = Codes.REWIND_PROPERTIES_TO_SHOW;
        break;
      case 'DIGITAL':
        propertiesToShow = Codes.DIGITAL_PROPERTIES_TO_SHOW;
        break;
      case 'PODCAST':
        propertiesToShow = Codes.PODCAST_PROPERTIES_TO_SHOW;
        break;
      case 'LIVE':
      default:
        propertiesToShow = Codes.LIVE_PROPERTIES_TO_SHOW;
    }

    newState = setPropertiesToShow(currentState, propertiesToShow);
    commit('SET_STATES_STAGING', newState);
  },

  /********************************************************************************************************************
   * APPLY PLAYER STATE MODIFIERS - to Staging
   *******************************************************************************************************************/
  /**
   * Get modified state controls using the AudioServices State Key that's been stored in `state.playerStates.current`
   * Combine staged state controls with the modified controls to finialize the new current state
   * Update controls state in 'store.stagingControlsState'.
   * @commit = commit `store.stagingControlsState` mutation
   */
  modifyState({ state, commit }) {
    // create copy that is de-referenced
    const stagingControlsState = JSON.parse(JSON.stringify(state.stagingControlsState));

    const stateName = state.playerStates.current;
    const modifiers = Codes.STATE_MODIFIERS[stateName];

    for (const property in modifiers) {
      for (const [key, value] of Object.entries(modifiers[property])) {
        stagingControlsState[property][key] = value;
      }
    }

    commit('SET_STATES_STAGING', stagingControlsState);
  },

  // ***** CALL CLIENT SERVICES TO SET PLAYER STATE (play/pause&stop/15sec both/skip forwards/drop thumb frac/scrub frac / eventually timeline items / play btn from Unity poster buttons)
  playButtonClicked({ state }) {
    ExchangeService.updateAudioTagStreamState(state.currentControlsState.play.iconType);
  },

  listenButtonClicked({ dispatch }) {
    dispatch('setStationHasPlayed', true, {root:true});
    ExchangeService.updateAudioTagStreamState('play');
  },

  controlButtonClicked({ state }, btn) {
    // ! This will need to be moved out of here, there will be a ticket for it.
    console.log('temp log', state.currentStateNumber.state);
    ExchangeService.updateAudioTagStreamState(btn);
  }
};

/**
 * GETTTERS
 */
export const getters = {
  getControls: state => state.currentControlsState,
  getStreamState: state => state.streamState,
  getStationType: state => state.stationTypes.current.toLowerCase(),
  getStateCode: state => state.currentStateNumber.state
};
