<template>
  <div id="volumeWrapper" class="volumeContainer reset" v-if="!isMobile">
    <div id="volumeValue">{{ volumeNum }}</div>

    <div id="volume" class="sliderWrapper">
      <base-rails
        base-rail-id="volume-railBase"
        rail-id="volume-railBase"
        rail-class="volume volume-rail"
        slide-direction="vertical"
        v-on:rcvdFract="rcvdFract"
        :rail-clickable="true"
      />
      <base-rails
        base-rail-id="volume-railBase"
        rail-id="volume-railFill"
        rail-class="volume volume-rail"
        slide-direction="vertical"
        :rail-fract="fract"
        v-on:rcvdFract="rcvdFract"
        :rail-clickable="true"
      />
      <base-thumb
        assoc-rail-id="volume-railBase"
        base-rail-id="volume-railBase"
        boundary-id="volumeWrapper"
        slide-direction="vertical"
        thumb-class="volume volume-thumb"
        :thumb-fract="fract"
        thumb-id="volume-thumb"
        v-on:rcvdFract="rcvdFract"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseRails from './baseComponents/BaseRails';
import BaseThumb from './baseComponents/BaseThumb';

export default {
  name: 'VolumeControl',

  data() {
    return {
      volumeRailBase: ''
    };
  },

  components: {
    BaseRails,
    BaseThumb
  },

  computed: {
    ...mapGetters({
      fract: 'volume/getFract',
      isMuted: 'volume/getIsMuted',
      isMobile: 'isMobile'
    }),

    volumeNum: function() {
      return Math.trunc((this.fract + 0.00001) * 100);
    }
  },

  mounted() {
    // TODO move this to rcvdPos where it is being used.
    this.volumeRailBase = document.getElementById('volume-railBase');
    this.presetVolume();
  },

  methods: {
    ...mapActions({
      requestVolumeChange: 'volume/requestVolumeChange',
      presetVolume: 'volume/presetVolume'
    }),

    rcvdFract(fract) {
      this.requestVolumeChange({ fraction: fract });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

#volumeWrapper {
  box-sizing: border-box;
  margin-bottom: 0;
  padding-top: 0;
  width: 30px;
  height: 0;
  text-align: center;
  background: #090527;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s, height 0s linear 0s, padding-top 0s linear 0s, margin-bottom 0s linear 0s;
  transition: opacity 0.5s, height 0s linear 0s, padding-top 0s linear 0s, margin-bottom 0s linear 0s;
}

#volumeMuteControlWrap:hover #volumeWrapper {
  opacity: 1;
  height: 107px;
  padding-top: 8px;
  margin-bottom: 2px;
  opacity: 1;
  -webkit-transition: opacity 0.5s, height 0s linear 0s, padding-top 0s linear 0s, margin-bottom 0s linear 0s;
  transition: opacity 0.5s, height 0s linear 0s, padding-top 0s linear 0s, margin-bottom 0s linear 0s;
}

#volumeValue {
  margin-bottom: 6px;
  height: 18px;
  cursor: default;
  color: $clr_white_white;
  font-family: $fnt_MaisonNeue-Bold;
  font-size: 12px;
}

#volume {
  position: relative;
  display: inline-block;
  width: 6px;
  height: 65px;
  padding: 0;
}

.embeddedPlayer {
  #volumeMuteControlWrap:hover #volumeWrapper {
    height: 100px;
  }
  #volumeWrapper {
    background: $clr_blue_med;
  }
  #volume {
    height: 58px;
  }
}
</style>

<style lang="scss">
// This styles section is unscoped so that the next css rule set can be applied as needed.
.mobile #playerBar #volumeMuteControlWrap {
  display: none;
}
</style>
