<template>
    <table class="scrubbarContainer">
      <tr>
        <td class="time startTime">{{ times.start }}</td>
        <td class="full">
          <div id="scrubbar" class="scrubbar reset">
            <slider-control />
            <ad-markers />
          </div>
        </td>
        <td class="time endTime">{{ times.stop }}</td>
      </tr>
    </table>

</template>

<script>
import { mapGetters } from 'vuex';
import SliderControl from './ScrubbarControl';
import AdMarkers from './AdMarkers';

export default {
  name: 'ScrubbarControl',

  components: {
    AdMarkers,
    SliderControl
  },

  computed: {
    ...mapGetters({
      startStopTimes: 'metadata/getShowTimes12hrFormat',
      duration: 'metadata/getDurationMinSecFormat',
      eplasedTime: 'scrubbar/getElapsedTimesMinSecFormat',
      stationType: 'stateCodes/getStationType'
    }),

    times: function() {
      let times = {};

      if (this.stationType === 'digital') {
        times.start = this.eplasedTime;
        times.stop = this.duration;
      } else {
        times = this.startStopTimes;
      }

      return times;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../assets/_variables2.scss';


.full {
    width: 100%;
    position: relative;
}

.scrubbarContainer {
  position: absolute;

  bottom: 11px;
  left: 15px;
  border-spacing: 0;
  height: $scrubbar_rail_height_x2;
  width: calc(100% - 15px);
  padding: 0;
  background-color: transparent;


  .time {
    font-size: 14px;
    color: $clr_white_white;
    opacity: 0.6;
    cursor: default;
  }

  .startTime {
    padding-right: 8px;
  }

  .endTime {
    padding-left: 8px;
  }

}


@media only screen and (min-width: 0px) and (max-width: 701px) {
  .scrubbarContainer {
    width: calc(100% - 31px);
    bottom: 15px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .scrubbarContainer {
    .time {
      font-size: 12px;
    }
    bottom: 10px;
  }
}




</style>
