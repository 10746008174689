export const namespaced = true;

const ERROR_MESSAGE_PLAYBACK = `Sorry, this content cannot be played.`;
const ERROR_MESSAGE_CONTENT_NOT_AVAILABLE = `Sorry, this content isn't currently available here.`;
const ERROR_MESSAGE_STREAM_NOT_AVAILABLE = `Sorry, this stream is currently unavailable.`;
const DELAY_BEFORE_ERROR_SCREEN_MS = 5000;
const ClientServicesErrorCodes = {
  FORBIDDEN: 0,
  PLAYER_ABORT: 1,
  BUFFER_STALLED_ERROR: 'mediaError:bufferStalledError',
  NETWORK_LEVEL_LOAD_TIMEOUT: 'networkError:levelLoadTimeOut',
  NUDGE_ON_STALL_ERROR: 'mediaError:bufferNudgeOnStall'
};

let errorScreenTimeout = null;
const clearErrorTimeout = function() {
  if (errorScreenTimeout) {
    console.log('clearing error screen timeout and setting to null', Date.now());
    window.clearTimeout(errorScreenTimeout);
    errorScreenTimeout = null;
  }
}

export const state = {
  notifications: [],
  transientErrors: [
    ClientServicesErrorCodes.PLAYER_ABORT,
    ClientServicesErrorCodes.BUFFER_STALLED_ERROR,
    ClientServicesErrorCodes.NETWORK_LEVEL_LOAD_TIMEOUT,
    ClientServicesErrorCodes.NUDGE_ON_STALL_ERROR
  ]
};

export const mutations = {
  ADD_NOTIFICATION(state, payload) {
    const type = payload.type || 'notification';
    state.notifications = [...state.notifications, { ...payload, type: type, id: Symbol() }];
  },
  ADD_ERROR(state, payload) {
    state.notifications = [...state.notifications, { ...payload, type: 'error', id: Symbol() }];
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = [];
  },
  REMOVE_NOTIFICATION(state, notificationId) {
    state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
  },
  CLEAR_ERRORS(state) {
    state.notifications = state.notifications.filter(notification => notification.type !== 'error');
  }
};

export const actions = {
  addNotification({ commit }, payload) {
    commit('ADD_NOTIFICATION', payload);
  },

  addError({ commit }, payload) {

    if (!payload.errorType && payload.error === ClientServicesErrorCodes.FORBIDDEN) {
      payload.errorType = 'FORBIDDEN';
    }

    payload.errorType = payload.errorType || ERROR_MESSAGE_PLAYBACK;

    switch (payload.errorType) {
      case 'NO_METADATA':
        payload.message = ERROR_MESSAGE_STREAM_NOT_AVAILABLE;
        break;

      case 'FORBIDDEN':
        payload.message = ERROR_MESSAGE_CONTENT_NOT_AVAILABLE;
        break;

      default:
        payload.message = ERROR_MESSAGE_PLAYBACK;
    }

    const isTransientError = payload.message === ERROR_MESSAGE_PLAYBACK && payload.error &&
                             (state.transientErrors.includes(payload.error) ||
                              state.transientErrors.includes(payload.error.msg));

    window.clearTimeout(errorScreenTimeout);

    if (isTransientError) {
      console.log('setting error timeout', Date.now());
      errorScreenTimeout = window.setTimeout(function() {
          console.log('showing error screen after timeout', Date.now());
          errorScreenTimeout = null;
          ExchangeService.updateAudioTagStreamState('stop');
          window.setTimeout(function() { commit('ADD_ERROR', payload); }, 20);
        },
        DELAY_BEFORE_ERROR_SCREEN_MS
      );
    } else {
      errorScreenTimeout = null;
      commit('ADD_ERROR', payload);
    }
  },

  clearNotifications({ commit }) {
    clearErrorTimeout();
    commit('CLEAR_NOTIFICATIONS');
  },

  clearErrors({ commit }) {
    clearErrorTimeout();
    commit('CLEAR_ERRORS');
  },

  removeNotification({ commit }, notificationId) {
    commit('REMOVE_NOTIFICATION', notificationId);
  }
};

export const getters = {
  getNotifications: state => {
    return state.notifications;
  },

  getErrors: state => {
    return state.notifications.filter(notification => notification.type === 'error');
  },

  getBlockingErrors: state => {
    return state.notifications.filter(notification => notification.type === 'error' && notification.errorType === 'NO_METADATA');
  },

  getForbiddenErrors: state => {
    return state.notifications.filter(notification => notification.type === 'error' && notification.errorType === 'FORBIDDEN');
  },

  getPlaybackErrors: state => {
    return state.notifications.filter(notification => notification.type === 'error' && notification.errorType !== 'NO_METADATA');
  }
};
