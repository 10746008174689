<template>
  <div v-show="fract > 0.015" class="marker" :data-segment-index="index + 1" :style="{ left: adjFract }">
    <div class="markerTimeContainer">
      <div class="markerTimeText">{{ time }}</div>
      <div class="markerTimeContainerPoint"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fract: {
      type: Number
    },
    time: {
      type: String
    },
    index: {
      type: Number
    }
  },
  computed: {
    adjFract() {
      return this.fract * 100 + '%';
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../../assets/_variables2.scss';

.marker {
  position: absolute;
  background-color: #d2d1ed;
  width: 2px;
  height: $scrubbar_rail_height;
  cursor: pointer;

  .markerTimeContainer {
    display: none;
    position: absolute;
    width: 60px;
    left: -29px;
    bottom: 17px;
    border: 0.5 solid white;
    border-radius: 10px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    background: white;
    text-align: center;
    text-transform: capitalize;

    .markerTimeText {
      position: relative;
      top: 1px;
      height: 17px;
      font-size: 12px;
      color: black;
    }

    .markerTimeContainerPoint {
      position: absolute;
      bottom: -8px;
      left: 0px;
      right: 0px;
      width: 2px;
      height: 10px;
      margin: 0 auto;
      background-color: white;
    }
  }

  &:hover {
    .markerTimeContainer {
      display: inline-block;
    }
  }
}
</style>
