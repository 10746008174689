import { formatTime12hr, formatTime12hrWithSeconds, formatTimeMinutesSeconds_seconds } from '../../utils/dateUtils';
export const namespaced = true;

export const state = {
  adBreaks: {
    artist: '',
    fracts: [], // an array of %'ages of the add breaks
    image: '',
    type: ''
  },

  update: {
    clock: '', // current/local date time GMT as milliseconds Unix
    duration: '', // length in seconds of segment  OR  "Infinity" <- string or num?
    fract: 0, // spot in stream that is playing
    liveFract: 0,
    playingLive: true, // true or false
    raw: '', // = timeupdate
    time: '', // currentTime from html5 audio element, which is seconds.
    trackOffset: '',
    type: ''
  },

  thumbMovementFract: 0,
  thumbIsMoving: false,
  railIsWaiting: false,

  showTimes: {
    start: 0,
    stop: 0
  }
};

export const mutations = {
  SET_AD_BREAKS(state, payload) {
    state.adBreaks.artist = payload.artist;
    state.adBreaks.fracts = payload.fracts;
    state.adBreaks.image = payload.image;
    state.adBreaks.type = payload.type;
  },

  SET_UPDATE(state, payload) {
    state.update.clock = payload.clock;
    state.update.duration = payload.duration;
    state.update.fract = payload.fract;
    state.update.liveFract = payload.liveFract;
    state.update.playingLive = payload.playingLive;
    state.update.raw = payload.raw;
    state.update.time = payload.time;
    state.update.trackOffset = payload.trackOffset;
    state.update.type = payload.type;
    // console.log('&&&&&&&&&& 21 mutations - state.update', state.update);
  },

  SET_THUMB_MOVEMENT_FRACT(state, payload) {
    state.thumbMovementFract = payload;
    //console.log('&&&&&&&&&& 24 mutations - state.thumbMovementFract', state.thumbMovementFract);
  },

  SET_THUMB_IS_MOVING(state, payload) {
    state.thumbIsMoving = payload;
    // console.log('&&&&&&&&&& 25 mutations -  state.thumbIsMoving', state.thumbIsMoving);
  },

  SET_RAIL_IS_WAITING(state, payload) {
    state.railIsWaiting = payload;
    // console.log('&&&&&&&&&& 25 mutations -  state.thumbIsMoving', state.thumbIsMoving);
  }
};

export const actions = {
  updateUpdate({ commit }, payload) {
    commit('SET_UPDATE', payload);
  },

  updateAdBreaks({ commit }, payload) {
    commit('SET_AD_BREAKS', payload);
  },

  udpateThumbPosition({ commit }, payload) {
    commit('SET_THUMB_POSITION', payload);
  },

  updateThumbMovementFract({ commit }, payload) {
    commit('SET_THUMB_MOVEMENT_FRACT', payload);
  },

  updateThumbIsMoving({ commit }, payload) {
    commit('SET_THUMB_IS_MOVING', payload);
  },

  updateRailIsWaiting({ commit }, payload) {
    commit('SET_RAIL_IS_WAITING', payload);
  },

  // outgoing to Exchanges Services
  requestFractChange({ state }, payload) {
    console.log('log state', state);
    ExchangeService.requestJumpToFraction(payload);
  }
};

export const getters = {
  getFract: state => {
    if (!state.thumbIsMoving) {
      return Math.abs(Math.trunc(state.update.fract) - state.update.fract);
    } else {
      return state.thumbMovementFract;
    }
  },

  getLiveFract: state => {
    return state.update.liveFract;
  },

  getAdBreaksFracts: state => {
    return state.adBreaks.fracts;
  },

  getAdBreakTimes: state => {
    const showLenthInMS = state.showTimes.stop - state.showTimes.start;

    return state.adBreaks.fracts.map(x => {
      const fractOfShow = showLenthInMS * x;
      const timeOfFractMs = state.showTimes.start + fractOfShow;
      return formatTime12hr(new Date(timeOfFractMs));
    });
  },

  getClockAtFract: state => {
    console.log('formatTime12hrWithSeconds(state.update.clock)', formatTime12hrWithSeconds(state.update.clock));

    return formatTime12hrWithSeconds(state.update.clock);
  },

  getClock: state => {
    return state.update.clock;
  },

  getShowTimesMinSecFormat: state => {
    return {
      start: formatTimeMinutesSeconds_seconds(state.update.trackOffset * 1000)
    };
  },

  getElapsedTimesMinSecFormat: state => {
    return formatTimeMinutesSeconds_seconds(state.update.trackOffset * 1000);
  }
};
