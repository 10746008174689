<template>
  <div id="metadata" class="metadata" :class="className">

    <div id="infoWrapper" :class="infoWrapperClassName">
      <div class="embeddedTypeContainer stationItemContainer">
        <div class="stationItem" :class="stationType"><span v-if="currentStreamTextModified === 'Live Radio'" class="soundSignature"></span>{{ currentStreamTextModified }} • <span class="streamCategory">{{ streamCategory }}</span></div>
      </div>

      <div id="songOrShowContainer" class="stationItemContainer songOrShowContainer" :class="{ marquee: songOrShowAnimate }" v-if="metadata.songOrShow && !playbackError">
        <div v-if="isMusicCategory" id="songOrShow" class="stationItem songOrShow">
          {{ metadata.artist }}<span v-if="metadata.artist && metadata.songOrShow"> - </span>{{ metadata.songOrShow }}
        </div>
        <div v-else id="songOrShow" class="stationItem songOrShow">{{ metadata.songOrShow }}</div>
      </div>

      <button id="stationContainer" class="line1 stationItemContainer" @click="this.gotoSDP">
        <div id="station" :class="stationItemClassName">
          {{ metadata.station }}
        </div>
      </button>

      <listen-on-audacy class="listenOnAudacyMetadataBlock" v-if="isExclusiveStation && stationHasPlayed" />

      <div v-if="isEmbeddedPlayer && playbackError" id="errorMessage" class="stationItemContainer">
        <error-message v-bind:error="playbackError" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorMessage from './ErrorMessage';
import { isMobileIOSDevice } from './../utils/utils'
import ListenOnAudacy from './ListenOnAudacy';

export default {
  name: 'MetadataBlock',
  components: {
    ErrorMessage,
    ListenOnAudacy
  },
  data() {
    return {
      elesToCheck: ['songOrShow'],
      stationAnimate: false,
      songOrShowAnimate: false,
      stationEle: {},
      posterEle: {},
      detail: {
        id: '',
        callsign: '',
        siteSlug: ''
      },
      posterWidth: 115
    };
  },
  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      streamCategory: 'getCategory',
      stationType: 'stateCodes/getStationType',
      playerType: 'getPlayerType',
      metadata: 'metadata/getMetadata',
      posterUrl: 'metadata/getPosterUrl',
      currentStreamText: 'metadata/getCurrentStreamText',
      playbackErrors: 'notifications/getPlaybackErrors',
      forbiddenErrors: 'notifications/getForbiddenErrors',
      slug: 'getSlug',
      airedText: 'metadata/getAiredText',
      stationHasPlayed: 'getStationHasPlayed'
    }),

    playbackError() {
      return this.playbackErrors && this.playbackErrors.length > 0 ? this.playbackErrors[0] : false; // should we be able to handle more than one error?
    },

    isMusicCategory() {
      return this.streamCategory === 'music' ? true : false;
    },

    showADCButton() {
      return this.forbiddenErrors && this.forbiddenErrors.length > 0;
    },

    isEmbeddedPlayer() {
      return this.playerType === 'embeddedPlayer' ? true : false;
    },

    hasSongOrShow() {
      return this.metadata && this.metadata.songOrShow && this.metadata.songOrShow.length > 0;
    },

    className() {

      let className = this.stationType === 'digital' && isMobileIOSDevice() ? 'live' : this.stationType;
      if (this.stationHasPlayed) {
        className += ' stationHasPlayed';
      }
      return className;
    },

    isExclusiveStation() {
      return this.stationType === 'digital';
    },

    stationItemClassName() {
      return `stationItem${ this.isExclusiveStation && (!this.stationHasPlayed || !this.hasSongOrShow) ? ' stationItemBold' : ''}`;
    },

    infoWrapperClassName() {
      return `infoWrapper ${ this.hasError ? 'playbackError' : ''}`;
    },

    currentStreamTextModified() {
      return this.currentStreamText === 'Audio Clip' ? 'Live Radio' : this.currentStreamText;
    }

  },

  mounted() {
    // onload resize
    window.addEventListener('resize', () => this.elesToCheck.forEach(this.checkToAnimate));
  },
  updated() {
    // onchange of the metadata
    this.elesToCheck.forEach(this.checkToAnimate);
  },
  methods: {
    openRDC() {
      ExchangeService.openRDCClick();
    },

    gotoSDP() {
      // need code for podcast
      // if (this.player.streamType !== 'podcast') ...
      if (this.isEmbeddedPlayer) window.open(this.slug ? `https://audacy.com/${this.slug}/listen` : `https://audacy.com`, window);
    },

    // next four functions are for animation, shoule they be put into thier own util file?
    getEle(className) {
      return this.$el.querySelector('.' + className);
    },
    checkToAnimate(ele) {
      // there are 4 times to check for update: onload, on change of stream, on change of song/show, on window resize
      const wrapper = this.getEle('infoWrapper');
      const container = this.getEle(`${ele}Container`);
      const content = this.getEle(ele);
      if (content) {
        this.checkWidthConditions(wrapper, content);
        this.marqueeSpeedHelper(wrapper, container, content);
      }
    },
    checkWidthConditions(wrapper, content) {
      const contentStyles = window.getComputedStyle(content);
      const wrapperWidth = parseFloat(wrapper.offsetWidth, 10);
      const contentWidth = Math.round(parseFloat(contentStyles.getPropertyValue('width'), 10));
      const contentPadding = Math.round(parseFloat(contentStyles.getPropertyValue('padding-left'), 10));
      this[content.id + 'Animate'] = contentWidth - contentPadding >= wrapperWidth;
    },
    marqueeSpeedHelper(wrapper, container, content) {
      const marqueeSpeed = 30;
      const nameWidth = parseInt(window.getComputedStyle(content).getPropertyValue('width'), 10);
      if (container.className.indexOf('marquee') > -1) {
        content.style.animationDuration = `${nameWidth / marqueeSpeed}s`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

.websiteLink,
.websiteLink:visited,
.websiteLink:hover {
  color: $clr_white_white;
}

.marquee {
  > div {
    animation: marquee 8s linear infinite;
  }
}

#metadata {
  button {
    padding: 0;
    background-color: transparent;
  }
}

.streamCategory {
  text-transform: capitalize;
}

.soundSignature {
  display: inline-block;
  height: 13px;
  width: 13px;
  margin-right: 4px;
  background: transparent $live_station_sound_signature no-repeat center center;
  position: relative;
  top: 1px;
  opacity: 1;
}

// embedded player
.embeddedPlayer {

  #metadata {
    width: calc(100% - 30px);
    margin: 0;
    margin-bottom: 6px;
    opacity: 1;
    line-height: normal;
    padding-top: 19px;


    #infoWrapper {
      width: 100%;
      margin: 0;
      letter-spacing: $fnt_letter_spacing_05;

      .stationItemContainer {
        white-space: nowrap;
        overflow: hidden;
      }

      .stationItem {
        display: inline-block;
      }

      .stationItemContainer:first-child .stationItem {
        vertical-align: top;
      }

      .embeddedTypeContainer {

        .stationItem {
          font-size: 14px;
          line-height: 18.9px;
          color: $clr_white_60;
          cursor: default;
          font-weight: 400;
        }
      }

      #stationContainer {
        height: 18.9px;

        #station {
          font-family: $fnt_MaisonNeue-Book;
          margin: 0;
          padding-right: 1px;
          vertical-align: top;
          font-size: 14px;
          line-height: 19px;
          color: $clr_white_60;
          cursor: pointer;
          letter-spacing: $fnt_letter_spacing_003_em;
          a {
            color: $clr_white_off_light;
            font-size: 12px;
            text-decoration: none;
          }
          &.stationItemBold {
            font-size: 18px;
            line-height: 19.8px;
            font-family: $fnt_MaisonNeue-Ext-Demi;
            color: $clr_white_white;
          }
        }
      }

      #songOrShowContainer {

        #songOrShow {
          font-family: $fnt_MaisonNeue-Ext-Demi;
          font-size: 18px;
          line-height: 18.9px;
          color: $clr_white_white;
          cursor: default;
        }
    }

      #errorMessage.stationItemContainer {
        white-space: normal;
      }
    }

    .listenOnAudacyMetadataBlock {
      position: static;
    }

    &.digital {
      padding-top: 27px;
    }


    &.digital.stationHasPlayed {
      padding-top: 18px;
    }

    &.stationHasPlayed {
      margin-bottom: 8px;
    }
  }

}

/**************************************************************************************************
 * Animation for marquee
**************************************************************************************************/

@keyframes marquee {
  0% {
    opacity: 0;
    transform: translate(0%, 0);
  }
  8% {
    opacity: 1;
  }
  12% {
    transform: translate(0%, 0);
  }
  99% {
    transform: translate(-102%, 0);
  }
  99.1% {
    opacity: 1;
  }
  99.5% {
    opacity: 0;
  }
  100% {
    transform: translate(-102%, 0);
  }
}

/**************************************************************************************************
 * Media breakpoints
**************************************************************************************************/

@media only screen and (min-width: 436px) and (max-width: 701px) {
  .embeddedPlayer {
    #metadata {

      padding-top: 27px;

      #infoWrapper {
        #songOrShowContainer {
          #songOrShow {
            font-size: 14px;
            line-height: 19px;
          }
        }

        #errorMessage.stationItemContainer {
          margin: 6px 0 10px 0;
        }

        #stationContainer {
          #station {
            font-size: 14px;
            line-height: 15px;
            &.stationItemBold {
              font-size: 14px;
              line-height: 15px;
            }
          }
        }
      }

    }


  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .embeddedPlayer {
    #metadata {

      padding-top: 0;

      &.digital {
        padding-top: 0;
      }

      #infoWrapper {
        margin: 0 0 0 15px;

        #songOrShowContainer {
          position: relative;
          top: 2px;
          
          #songOrShow {
            font-size: 14px;
            line-height: 15px;
          }
        }

        #stationContainer {
          #station {
            font-size: 14px;
            line-height: 15px;
            &.stationItemBold {
              font-size: 14px;
              line-height: 15px;
            }
          }
        }

      }

      &.digital {
        #songOrShowContainer {
          #songOrShow {
            font-size: 14px;
            line-height: 15.4px;
          }
        }

        #stationContainer {
          #station {
            font-family: $fnt_MaisonNeue-Book;
            font-size: 14px;
            line-height: 18.9px;
          }
        }

      }

      &.digital.stationHasPlayed {
        padding-top: 0;

        #stationContainer {
          #station {
            font-family: $fnt_MaisonNeue-Book;
            font-size: 14px;
            line-height: 18.9px;
          }
        }
      }


    }

    #errorMessage.stationItemContainer {
      margin: 0 0 10px 0;
    }
  }
}
</style>
