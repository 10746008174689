<template>
  <button id="skipButton" :class="classNames" @click="checkToEmit('next', $event)">  <span id="skipCounter">{{ skipNumber }} Skip{{ isPlural }}</span></button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'SkipButton',


  computed: {
    ...mapGetters({
      controls: 'stateCodes/getControls',
      skipsAvailable: 'metadata/getSkips',
      stationHasPlayed: 'getStationHasPlayed'
    }),

    skipNumber: function() {
      if (this.skipsAvailable || this.skipsAvailable === 0) return this.skipsAvailable;
      return 6;
    },

    classNames: function() {
      let classes ="pillBtn btnNext";
      if (this.skipsAvailable && this.skipsAvailable !== 0) {
        classes += " skippable";
      }
      return classes;
    },

    isSkippableClass: function() {
      return this.skipsAvailable && this.skipsAvailable !== 0 ? 'skippable' : '';
    },

    isPlural: function() {
      return this.skipsAvailable !== 1 ? 's' : '';
    }

  },
  methods: {
    ...mapActions({
      btnClicked: 'stateCodes/controlButtonClicked'
    }),
    checkToEmit: function(param, event) {
      console.log('chekcing to emit');
      if (this.skipsAvailable && this.skipsAvailable !== 0 && this.skipsAvailable !== this.previousSkipsAvailable) {
        this.btnClicked(param);
        this.previousSkipsAvailable = this.skipsAvailable;
      } else {
        event.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../assets/_variables2.scss';

button {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 10px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
  background-size: contain;
  background-repeat: no-repeat;
  white-space: nowrap;

  &:hover {
    opacity: $hoverOpacity-7;
  }

}

.btnNext {

  vertical-align: top;
  background: transparent $svg_next_outline_white_A2 no-repeat left 12px center / 20px 20px;
  width: 86px;
  height: 44px;
  margin: auto;
  border-radius: 10px;
  color: white;

  &:hover {
    opacity: 1;
  }

  &:not(.skippable) {
    cursor: default;
    opacity: 0.6;

    #skipCounter:hover {
      opacity: 0.6;
    }


  }

  &.disabled {
    color: $clr_dusk_light;
    cursor: default;
    opacity: 0.6;

    &:hover {
      opacity: 0.6;
    }
  }

  #skipCounter {
    display: inline-block;
    vertical-align: top;
    padding-left: 25px;
    font-family: 'MaisonNeueWEB-Book';
    font-size: 12px;
    opacity: 0.6;
    line-height: 44px;

    &:hover {
      opacity: 1;
    }
  }
}

</style>
