/* eslint-disable prettier/prettier */
import Vue from 'vue';
import Vuex from 'vuex';
import * as stateCodes from './modules/stateCodes';
import * as metadata from './modules/metadata';
import * as volume from './modules/volume';
import * as scrubbar from './modules/scrubbar';
import * as notifications from './modules/notifications';
import * as sharing from './modules/sharing';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    stateCodes,
    metadata,
    volume,
    scrubbar,
    notifications,
    sharing
  },

  state: {
    state: {
      dataObj: {},
      state: '',
      playerType: '',
      category: ''
    },

    schedule: null,
    station: null,
    slug: '',
    isMobile: false,
    websiteBaseUrl: 'https://audacy.com',
    shouldPlayFromEpochTime: false,
    stationHasPlayed: false
  },

  mutations: {
    // ***** EVENT UPDATES *****
    SET_STATE(state, payload) {
      state.state.dataObj = payload.dataObj;
      state.state.category = payload.dataObj && payload.dataObj.category ? payload.dataObj.category.toLowerCase() : state.state.category;
    },

    SET_ADBREAKS(state, payload) {
      state.adBreaks.artist = payload.artist;
      state.adBreaks.image = payload.image;
      state.metadata.title = payload.title;
    },

    SET_UPDATE(state, payload) {
      state.update.clock = payload.clock;
      state.update.duration = payload.duration;
      state.update.playingLive = payload.playingLive;
      state.update.raw = payload.raw;
      state.update.time = payload.time;
    },

    SET_THUMB_POSITION(state, payload) {
      state.stream2.thumbPos = payload.progress;
    },

    SET_PLAYER_TYPE(state, payload) {
      state.state.playerType = payload;
    },

    // ***** SHOWS *****
    SET_SLUG(state, payload) {
      state.slug = payload;
    },

    // !Ken if versionInfo is being stored in state, what is using it from state?
    SET_VERSION_INFO(state, payload) {
      state.versionInfo = payload;
    },

    SET_IS_MOBILE(state, payload) {
      state.isMobile = payload;
    },

    SET_REWIND_TIME(state, payload) {
      state.rewindTime = payload;
    },

    SET_SCHEDULE(state, payload) {
      state.schedule = payload;
    },

    SET_STATION(state, payload) {
      state.station = payload;
    },

    SET_STATION_HAS_PLAYED(state, payload) {
      state.stationHasPlayed = payload;
    },

    SET_INTERACTIVE(state, payload) {
      state.isInteractive = payload;
    },

    SET_SHOULD_PLAY_FROM_EPOCH_TIME(state, payload) {
      state.shouldPlayFromEpochTime = payload;
    },

  },

  actions: {
    // *************** EVENT UPDATES ***************
    updateState({ commit }, payload) {
      commit('SET_STATE', payload);
    },

    // TEMP for switching stations on dev ui from standard to embed
    updatePlayerType({ commit }, payload) {
      commit('SET_PLAYER_TYPE', payload === 'audacyPlayer' ? 'audacyPlayer' : 'embeddedPlayer');
    },

    updateSlug({ commit }, payload) {
      commit('SET_SLUG', payload);
    },

    setVersionInfo({ commit }, payload) {
      commit('SET_VERSION_INFO', payload);
    },

    setIsMobile({ commit }, payload) {
      commit('SET_IS_MOBILE', payload);
    },

    setRewindTime({ commit }, payload) {
      commit('SET_REWIND_TIME', payload);
    },
    setSchedule({ commit }, payload) {
      commit('SET_SCHEDULE', payload);
    },

    setStation({ commit }, payload) {
      commit('SET_STATION', payload);
    },

    setStationHasPlayed({ commit }, payload) {
      commit('SET_STATION_HAS_PLAYED', payload);
    },

    setInteractive({ commit }, payload) {
      commit('SET_INTERACTIVE', payload);
    },

    setShouldPlayFromEpochTime({ commit }, payload) {
      commit('SET_SHOULD_PLAY_FROM_EPOCH_TIME', payload);
    },

  },

  getters: {
    getStreamCategory: state => {
      return state.state.category;
    },

    getCategory:    state => state.state.category.toLowerCase(),
    getPlayerType:  state => {
      return state.state.playerType

    },

    isInteractive:  state => state.isInteractive,

    isMobile:       state => state.isMobile,


    // ! for slider control and should be reworked.
    getSlug: state => {
      return state.slug;
    },

    // ! should this be dealt with in metadata, would rather not have two places for setting metadata info
    getStationName: state => {
      return state.state.dataObj.name;
    },

    getRewindTime: state => {
      return state.rewindTime;
    },

    getSchedule: state => {
      return state.schedule;
    },

    getStation: state => {
      return state.station;
    },

    getStationHasPlayed: state => {
      return state.stationHasPlayed;
    },

    shouldPlayFromEpochTime: state => {
      return state.shouldPlayFromEpochTime;
    },

    getWebsiteBaseUrl: state => {
      return state.websiteBaseUrl;
    },

    getWebsiteHref: state => {
      return state.slug ? `${state.websiteBaseUrl}/${state.slug}/listen` : state.websiteBaseUrl;
    }
  }
});
