<template>
  <!-- v-bind:is determines whether to use the regular player layout or the embed player -->
  <component :is="playerType" :layout-class="classNames" />
</template>

<script>
import { mapGetters } from 'vuex';
import ExchangeService from './services/exchangeService.js';
import EmbeddedPlayer from './layouts/LayoutEmbeddedPlayer.vue';
import { isMobileIOSDevice } from './utils/utils'

export default {
  name: 'PlayerContainer',

  components: {
    ExchangeService,
    EmbeddedPlayer
  },

  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      streamCategory: 'getCategory',
      playerType: 'getPlayerType',
      stationType: 'stateCodes/getStationType',
      stateCode: 'stateCodes/getStateCode'
    }),

    classNames() {
      const playerContainerEle = document.getElementById('playerContainer');
      const pcClasses = playerContainerEle ? playerContainerEle.classList : '';

      const stationTypeClass = isMobileIOSDevice() && this.stationType === 'digital' ? 'live' : this.stationType;

      let classes = [this.playerType, stationTypeClass, this.streamCategory, `sc-${this.stateCode}`];
      if (pcClasses && pcClasses.contains('jtl')) classes.push('jtl');
      if (this.isMobile) classes.push('mobile');

      return classes;
    }
  },

  mounted() {
    window.ExchangeService = new ExchangeService();
  }
};
</script>

<style lang="scss">
@import './assets/all.scss';

html,
body {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: $fnt_MaisonNeue-Book;
}

#playerContainer {

  // __________ Global Element Resets ____________________
  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  //  clay css sets this to 1000, but not necessary since #playerContainer is set to
  & > div {
    z-index: 0 !important;
  }

  button {
    border: 0px;
    font-family: $fnt_MaisonNeue-Book;

    // left focus outline for accessibility, not sure if outline is needed or not, but it does help to see the tab order.
    // can be turned off later. :)
    // &:focus {
    //   outline: 0;
    // }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &.disabled:hover {
      opacity: inherit;
    }
  }

  .mobile button:hover {
    opacity: inherit;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.embeddedPlayer #playerContainer {
  position: relative;
  overflow: hidden;
  height: 220px;
}
</style>
