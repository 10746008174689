<template>
  <div id="shareDialogWrapper">
    <div class="posterWrapper">
      <base-image v-if="metadata.image" id="poster" :src="`${posterUrl}?width=${posterWidth}`" alt="Song or Show Poster Image"></base-image>
    </div>
    <div class="infoWrapper">
      <div class="shareOptionsWrapper">
        <div class="dialogTitle">Share This <span>Aired</span> Show</div>
        <div class="shareOptions">
          <div id="currentTime" class="radio-item">
            <input type="radio" id="currentTimeRadio" name="sharing" value="timecode" v-model="shareType" />
            <label id="currentAudioLabel" for="currentTimeRadio"><span>Share From {{ shareClockTime }}</span></label>
          </div>
          <div id="beginningOfShow" class="radio-item">
            <input type="radio" id="beginningTimeRadio" name="sharing" value="beginning" v-model="shareType" />
            <label for="beginningTimeRadio"><span>Share From Beginning of Show</span></label>
          </div>
        </div>
        <div class="shareLinks">
          <!-- TODO: make these icon button components -->
          <div class="iconWrapper iconWrapperFacebook">
            <a id="facebookShareLink" :href="facebookLink" target="_blank" alt="Share on Facebook"></a>
            <div class="shareLinkText">Facebook</div>
          </div>
          <div class="iconWrapper iconWrapperTwitter">
            <a id="twitterShareLink" :href="twitterLink" target="_blank" alt="Share on Twitter"></a>
            <div class="shareLinkText">Twitter</div>
          </div>
          <div class="iconWrapper iconWrapperEmail">
            <a id="emailSharing" :href="emailLink" target="_blank" alt="Share by Email"></a>
            <div class="shareLinkText">Email</div>
          </div>
          <div class="iconWrapper iconWrapperLink copyLinkToClipboard" @click="copyShareLinkToClipboard()">
            <a id="linkSharing" target="_blank" alt="Share by Copying Link"></a>
            <div class="shareLinkText">{{ copyLinkText }}</div>
          </div>
        </div>
      </div>
    </div>
    <span class="closeButton" @click="closeShareDialog(false)"><span class="closeX"></span></span>
    <open-sdp-control />
  </div>
</template>

<script>
import BaseImage from '../components/baseComponents/BaseImage';
import OpenSdpControl from '../components/OpenSdpControl';
import { mapGetters, mapActions } from 'vuex';
import { copyToClipboard } from '../utils/utils';

const SHARE_MESSAGE = encodeURIComponent(`Listen to this moment on Audacy`);
const COPY_LINK = 'Copy Link';
const COPIED = 'Copied!';

export default {
  name: 'ShareDialog',

  components: {
    BaseImage,
    OpenSdpControl
  },

  data() {
    return {
      posterWidth: 115
    };
  },

  computed: {
    ...mapGetters({
      metadata: 'metadata/getMetadata',
      shareLink: 'sharing/getShareLink',
      copyLinkText: 'sharing/getCopyLinkText',
      shareTypeFromStore: 'sharing/getShareType',
      clockTime: 'scrubbar/getClock',
      shareClockTime: 'sharing/getShareClockTime',
      rewindTime: 'getRewindTime',
      posterUrl: 'metadata/getPosterUrl'
    }),

    shareType: {
      get() {
        return this.shareTypeFromStore;
      },
      set(newType) {
        console.log('setting, new type is', newType);
        this.setShareType(newType);
        this.getSharingUrl();
        return newType;
      }
    },
    facebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}&quote=${SHARE_MESSAGE}`;
    },
    twitterLink() {
      return `http://twitter.com/share?url=${this.shareLink}&text=${SHARE_MESSAGE}`;
    },
    emailLink() {
      const emailBody = encodeURIComponent(`Check out this shared moment. Listen now: ${this.shareLink}`);
      return `mailto:?subject=${SHARE_MESSAGE}&body=${emailBody}`;
    }
  },
  methods: {
    ...mapActions({
      closeShareDialog: 'sharing/setShowShareDialog',
      getSharingUrl: 'sharing/getSharingUrl',
      setCopyLinkText: 'sharing/setCopyLinkText',
      setShareType: 'sharing/setShareType',
      setShareClockTime: 'sharing/setShareClockTime',
      openShareDialog: 'sharing/setShowShareDialog'
    }),
    copyShareLinkToClipboard() {
      copyToClipboard(this.shareLink)
        .then(() => this.setCopyLinkText(COPIED))
        .catch(() => console.log('error'));
    }
  },
  mounted() {
    const shareTime = this.clockTime || this.rewindTime;
    this.setShareClockTime(shareTime);
    this.setCopyLinkText(COPY_LINK);
    this.getSharingUrl();
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

#shareDialog {
  font-family: $fnt_MaisonNeue-Ext-Medium;
  color: $clr_white_white;
  letter-spacing: $fnt_letter_spacing_05;
  width: 100%;

  .infoWrapper {
    display: inline-block;
    width: calc(100% - 140px);
  }

  .dialogTitle {
    display: inline-block;
    line-height: 18px;
    font-size: 15px;
    margin-bottom: 8px;
    color: $clr_white_white;
  }

  .posterWrapper {
    display: inline-block;
    height: 86px;
    width: 86px;
    margin-right: 12px;
    vertical-align: top;
  }

  .closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    color: $clr_white_off_light;
    display: flex;
    align-items: center;
    cursor: pointer;

    .closeX {
      display: inline-block;
      background: $svg_x_close_outline_white;
      background-size: cover;
      height: 16px;
      width: 16px;
      margin-left: 6px;
    }
  }

  .shareLinks {
    color: $clr_grey_med_light;
  }

  .shareOptionsWrapper {
    display: inline-block;
    margin-left: 12px;
    width: 100%;
  }

  .shareOptions {
    font-size: 10px;
    margin-bottom: 8px;
    color: $clr_grey_med_light;
    .radio-item {
      display: inline-block;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    input[type='radio'] {
      margin-left: 0;
    }
  }

  .closeButton {
    font-size: 10px;
    line-height: 12px;
  }

  .iconWrapper {
    margin-right: 30px;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    font-family: $fnt_MaisonNeue-Book;
    cursor: pointer;

    a {
      &:hover {
        cursor: pointer;
      }

      display: grid;
      justify-self: center;
      height: 32px;
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    #facebookShareLink {
      background: $clr_dusk_light;
      -webkit-mask-image: $svg_facebook_solid_white;
      mask-image: $svg_facebook_solid_white;
      -webkit-mask-size: 12px 24px;
      mask-size: 12px 24px;
      text-align: center;
    }

    #twitterShareLink {
      background: $clr_dusk_light;
      -webkit-mask-image: $svg_twitter_solid_white;
      mask-image: $svg_twitter_solid_white;
      -webkit-mask-size: 24px 19px;
      mask-size: 24px 19px;
    }

    #emailSharing {
      background: $clr_dusk_light;
      -webkit-mask-image: $svg_email_solid_white;
      mask-image: $svg_email_solid_white;
      -webkit-mask-size: 24px 18px;
      mask-size: 24px 18px;
    }

    #linkSharing {
      background: $clr_dusk_light;
      -webkit-mask-image: $svg_copy_link_white;
      mask-image: $svg_copy_link_white;
      -webkit-mask-size: 24px 18px;
      mask-size: 24px 18px;
    }
  }

  input[type='radio'] {
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 0px;
    margin: 0px;
  }

  input[type='radio'] + label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  input[type='radio'] + label:before {
    content: '';
    width: 16px;
    height: 16px;
    padding: 2px;
    margin-right: 8px;
    vertical-align: text-top;
    background-clip: content-box;
    border: 1px solid $clr_grey_light;
    background-color: $clr_navy_dark;
    border-radius: 50%;
  }

  input[type='radio']:checked + label:before {
    border: 1px solid $clr_orange_dark;
    background-color: $clr_orange_dark;
  }
}



@media only screen and (min-width: 0px) and (max-width: 701px) {
  #shareDialog {
    .dialogTitle {
      display: inline-block;
      line-height: 18px;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .dialogTitle {
      line-height: 17px;
      font-size: 14px;
    }

    .infoWrapper {
      width: calc(100% - 100px);
    }

    .shareOptionsWrapper {
      margin-left: 4px;
    }

    .shareOptions {
      margin-bottom: 5px;
    }

    .iconWrapper {
      margin-right: 4px;
      width: 50px;

      a {
        height: 20px;
      }
      #facebookShareLink {
        -webkit-mask-size: 9px 16px;
        mask-size: 9px 16px;
      }
      #twitterShareLink {
        -webkit-mask-size: 18px 15px;
        mask-size: 18px 15px;
      }

      #emailSharing {
        -webkit-mask-size: 18px 14px;
        mask-size: 18px 14px;
      }

      #linkSharing {
        background: $clr_dusk_light;
        -webkit-mask-image: $svg_copy_link_white;
        mask-image: $svg_copy_link_white;
        -webkit-mask-size: 16px 16px;
        mask-size: 16px 16px;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  #shareDialog {

    .posterWrapper {
      margin-right: 12px;
      height: 76px;
      width: 76px;
    }

    .shareOptionsWrapper {
      margin-left: 0px;
    }

    .shareOptions {
      .radio-item {
        display: block;
        margin-bottom: 6px;
      }
    }

    .infoWrapper {
      display: inline-block;
      width: calc(100% - 88px);
    }

    .closeText {
      display: none;
    }

    input[type='radio'] + label:before {
      width: 12px;
      height: 12px;
    }

    .shareLinks {
      position: absolute;
      bottom: 8px;
      left: 100px;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .iconWrapper.iconWrapperFacebook {
      width: 50px;
    }

    .iconWrapper.iconWrapperTwitter {
      width: 40px;
    }

    .iconWrapper.iconWrapperEmail {
      width: 32px;
    }

    .iconWrapper.iconWrapperLink {
      width: auto;
      min-width: 47px;
    }
  }
}


</style>
