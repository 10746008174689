<template>
  <div :id="thumbId" :class="thumbClass" class="thumb" :style="computedFract" v-on:mousedown.prevent="onMouseDown">
    <div id="timeBox" class="showTime">
      <span id="timeText">{{ clockAtFract }}</span>
      <span v-if="thumbId !== 'volume-thumb'" class="fractText">Stream fraction: {{ (100 * thumbFract).toFixed(3) }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseThumb',
  inheritAttrs: false,

  props: {
    assocRailId: { type: String },
    baseRailId: { type: String },
    boundaryId: { type: String },
    slideDirection: { type: String },
    thumbClass: { type: String },
    thumbFract: { type: Number },
    thumbId: { type: String },
    clockAtFract: { type: String }
  },

  data() {
    return {
      // element objs
      assocRail_ele: {},
      baseRail_ele: {},
      mousingBoundary_ele: {},
      thumb_ele: {},

      // for determining if the thumb moved
      clientXOnClick: 0,
      clientYOnClick: 0,

      // for computational purposes
      assocRail_start: 0,
      assocRail_end: 0,
      assocRail_left: 0,
      assocRail_top: 0,
      baseRail_width: 0,
      baseRail_height: 0,
      computedThumb_fract: 0,

      // flags
      isDragging: false,
      isWaiting: false,
      hasMoved: false
    };
  },

  computed: {
    /*
     *
     */
    computedFract: function() {
      // using `this.thumbFract` here is only to maintain the reactive state between that prop and the component :style...
      let style;

      if (this.thumbFract || this.thumbFract === 0) {
        if (this.slideDirection === 'horizontal') {
          this.computedHorizFract();
          style = { left: this.computedThumb_fract * 100 + '%' };
        } else if (this.slideDirection === 'vertical') {
          style = { top: Math.trunc((1 - this.thumbFract) * 100) + '%' };
        }
      }

      return style;
    }
  },

  mounted() {
    this.assocRail_ele = document.getElementById(this.assocRailId);
    this.baseRail_ele = document.getElementById(this.baseRailId);
    this.mousingBoundary_ele = document.getElementById(this.boundaryId);
    this.thumb_ele = document.getElementById(this.thumbId);
    this.assocRail_start = 0;
  },

  methods: {
    ...mapActions({
      updateThumbMovementFract: 'scrubbar/updateThumbMovementFract',
      updateThumbIsMoving: 'scrubbar/updateThumbIsMoving',
      updateRailIsWaiting: 'scrubbar/updateRailIsWaiting'
    }),

    /*
     *
     */
    computedHorizFract() {
      if (!this.isDragging && !this.isWaiting) {
        this.computedThumb_fract = this.thumbFract;
      } else if (this.isWaiting) {
        this.isWaiting = false;
        // console.log('========== isWaiting is false');  This update is not working as expected.
        this.updateRailIsWaiting(false);
      }
    },

    /*
     *
     */
    onMouseDown() {
      // for horiz only
      if (this.slideDirection === 'horizontal') {
        this.baseRail_width = this.baseRail_ele.offsetWidth;
        this.assocRail_left = this.assocRail_ele.getBoundingClientRect().left;
      } else if (this.slideDirection === 'vertical') {
        this.baseRail_height = this.baseRail_ele.offsetHeight;
        this.assocRail_end = this.assocRail_ele.offsetHeight;
        this.assocRail_top = this.assocRail_ele.getBoundingClientRect().top;
      }

      // these are to help determine if the mouse, on mousedown, actually moves. Sometimes mousemove triggers on mousedown.
      this.clientYOnClick = event.clientY;
      this.clientXOnClick = event.clientX;

      // set up listeners. They are to only work when the element is clicked on are are removed when the element is no longer being clicked on.
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
      this.mousingBoundary_ele.addEventListener('mouseleave', this.onMouseLeave);
    },

    /*
     *
     */
    onMouseMove(event) {
      // volume
      if (this.slideDirection === 'vertical' && event.clientY !== this.clientYOnClick) {
        let pos = event.clientY - this.assocRail_ele.getBoundingClientRect().top;

        // Lock the thumb within the boundaries of the associated rail.
        if (pos < this.assocRail_start) pos = this.assocRail_start;
        if (pos > this.assocRail_end) pos = this.assocRail_end;

        this.computedThumb_fract = Math.round((1 - pos / this.baseRail_height) * 100) / 100;
        this.$emit('rcvdFract', this.computedThumb_fract);
      }

      // scrubbar
      if (this.slideDirection === 'horizontal' && event.clientX !== this.clientXOnClick) {
        this.updateThumbIsMoving(true);
        this.updateRailIsWaiting(true);
        this.hasMoved = true;
        this.isDragging = true;
        this.assocRail_end = this.assocRail_ele.offsetWidth;

        // Lock the thumb within the boundaries of the associated rail.
        let pos = event.clientX - this.assocRail_left;
        if (pos < this.assocRail_start) pos = this.assocRail_start;
        if (pos > this.assocRail_end) pos = this.assocRail_end;

        // calculate the fraction from pos / the width of the rail
        this.computedThumb_fract = pos / this.baseRail_width;
        this.updateThumbMovementFract(this.computedThumb_fract);
      }
    },

    onMouseUp() {
      this.removeMouseEvents();
    },

    onMouseLeave() {
      this.removeMouseEvents();
    },

    /*
     *
     */
    removeMouseEvents() {
      // This if is only used with scrubbar and it will emit new position if the movement has stopped and the thumb was actually moved.
      if (this.hasMoved) {
        this.updateThumbIsMoving(false);
        this.isDragging = false;
        this.isWaiting = true;
        this.hasMoved = false;
        this.$emit('rcvdFract', this.computedThumb_fract);
      }

      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
      this.mousingBoundary_ele.removeEventListener('mouseleave', this.onMouseLeave);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/_variables2.scss';
/****************************
 * WEB-PLAYER VOLUME RAILS
/***************************/
.thumb {
  position: absolute;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: $clr_white_white;
  border-radius: 50%;
  box-shadow: 0 0 10px $clr_black_off;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 150ms cubic-bezier(0, 0.25, 0.25, 1);
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

#scrubbar-thumb {
  z-index: 10;
  top: 50%;
  width: $scrubbar_rail_height_x2;
  height: $scrubbar_rail_height_x2;
}

#volume-thumb {
  left: 50%;
  width: $scrubbar_rail_height_x2;
  height: $scrubbar_rail_height_x2;
}

#timeBox {
  position: relative;
  left: -100%;
  bottom: -15px;
  font-size: 10px;
}

.fractText {
  display: none;
  width: 120px;
}

.embeddedPlayer {
  .scrubbarContainer {
    #timeBox {
      display: none;
    }
  }
}
</style>
