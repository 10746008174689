<template>
  <button :id="btnId" :class="buttonClass" v-on="$listeners">
    <span class="btnIcon"></span>
    <span class="btnText"><slot></slot></span>
  </button>
</template>

<script>
export default {
  name: 'BaseButtonsOthers',
  inheritAttrs: false,
  props: {
    btnId: {
      type: String
    },
    buttonClass: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../../assets/_variables2.scss';

button {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 0 7.5px;
  padding: 0px;
  background: $clr_navy_dark;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;

  .btnIcon {
    width: 10px;
    height: 16px;
    display: none;
  }
  .btnText {
    display: none;
  }

  &:hover {
    opacity: $hoverOpacity-7;
  }
}

#btnShareModal {
  width: 24px;
  height: 24px;
  background: $svg_share_outline_purple_light center / 24px 24px no-repeat;

  .btnText {
    position: relative;
    display: none;
    top: -20px;
    left: -3px;
    width: 32px;
    padding-bottom: 10px;
    color: $clr_purple_light;
    font-size: 12px;
  }

  &:hover {
    top: 10px;

    .btnText {
      display: block;
    }
  }
}


#btnPodcastMenu {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $svg_horiz_3dots_purple_light center / contain no-repeat;
}

#btnMinimizeToggle {
  position: absolute;
  bottom: 1px;
  right: 6px;
  width: 84px;
  height: 13px;
  background: $svg_downLeftArrow_icon left top 2px / 8px 8px no-repeat;
  opacity: 0.5;

  .btnText {
    display: block;
    margin-left: 8px;
    padding-bottom: 2px;
    color: $clr_grey_light;
    font-size: 10px;

    span:first-child {
      display: block;
    }
    span:last-child {
      display: none;
    }
  }

  &:hover {
    opacity: 1;
  }
}


button.pillBtn {
  position: absolute;
  top: 10px;
  right: 12px;
  vertical-align: top;
  height: 16px;
  margin: 0;
  background: $clr_grad_blue_purple;
  border-radius: 10px;
  color: white;
  text-align: left;
  font-size: 9px;

  .btnText {
    vertical-align: top;
    display: inline-block;
    height: 16px;
    margin: 0 10px 0 2px;
    padding-top: 2px;
  }

  a:link,
  a:visited {
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-block;
    vertical-align: top;
    height: 16px;
    color: white;
    font-size: 9px;
    text-align: left;
    text-decoration: none;
  }

  a:hover,
  a:active {
    color: white;
  }

  &.sdpLink {
    width: 75px;

    a:link,
    a:visited {
      width: 75px;
      padding: 2px 0 0;
      text-align: center;
    }

    .btnIcon {
      display: none;
      // display: inline-block;
      // margin-left: 10px;
      // background: $logo_a_solid_white no-repeat center center / 10px 8px;
    }
  }

  &.shareBtn {
    width: 82px;

    .btnIcon {
      display: inline-block;
      margin-left: 10px;
      background: $svg_share_outline_white no-repeat center center / contain;
    }
  }
}

button.listenBtn {
  margin-left: 0;
  border-radius: 100px;
  width: auto;
  color: #fff;
  padding: 0 28px;
  position: relative;
  background-color: $clr_orange_dark;
  white-space: nowrap;
  height: 54px;
  border-radius: 100px;
  font-size: 16px;

  .btnIcon {
    display: inline-block;
    margin-right: 5.13px;
    padding: 0;
    width: 0;
    height: 0;
    border-top: 4.7px solid transparent;
    border-bottom: 4.7px solid transparent;

    border-left:7.9px solid #fff;
    //position: relative; top: px;
    // height: 9.75px;
    // width: 9.75px;
    // background: #FFFFFF;
    // transform: rotate(90deg);
    // padding: 0;

  }
  .btnText {
    vertical-align: top;
    display: inline-block;
    margin: 0;
    font-weight: 700;
    font-family: $fnt_MaisonNeue-Book;
    letter-spacing: 0.02em;

  }
}

#shareDialogWrapper {
  button.pillBtn {
    &.sdpLink {
      top: auto;
      left: 12px;
      bottom: 8px;
      right: auto;
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 701px) {
  button.listenBtn {
    height: 48px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  button.listenBtn {
    height: 48px;
  }
}
</style>
