<template>
  <div id="posterWrapper" @click="this.gotoSDP">
    <base-image id="poster" :src="`${posterUrl}?width=${posterWidth}`" alt="Song or Show Poster Image" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseImage from '../components/baseComponents/BaseImage.vue';

export default {
  name: 'PosterImage',
  components: {
    BaseImage
  },
  data() {
    return {
      posterWidth: 190
    };
  },
  computed: {
    ...mapGetters({
      posterUrl: 'metadata/getPosterUrl',
      slug: 'getSlug'
    }),

  },
  methods: {
    openRDC() {
      ExchangeService.openRDCClick();
    },

    gotoSDP() {
      // need code for podcast?
      window.open(this.slug ? `https://audacy.com/${this.slug}/listen` : `https://audacy.com`, window);
    },

  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

#posterWrapper {
  width: 190px;
  height: 190px;
  margin-right: 0;
  vertical-align: top;
  cursor: pointer;
  padding: 0;

  display: inline-block;


  img {
      border-radius: 4px;
      box-sizing: content-box;
  }

}



/**************************************************************************************************
 * Media breakpoints
**************************************************************************************************/

@media only screen and (min-width: 436px) and (max-width: 701px) {
  #posterWrapper {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {

  #posterWrapper {
    width: 80px;
    height: 80px;
  }
}
</style>
