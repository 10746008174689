/** ***************************************************************************************************
    Date Formatting Functions - add as needed.  Format ds (date string) and return.
***************************************************************************************************** */
/**
 * Being used in the scrubbar start/end times & for metadata aired times
 * @param {string} ds = a date string formatted as: 1594260000000
 * @returns {string} a date string formatted as: 6:00pm
 */
export function formatTime12hr(ds) {
  return ds ? `${getHours(ds)}:${getMinutes(ds)}${getMeridian(ds)}` : '';
}

/**
 * Being used in the scrubbar listening point time
 * @param {string} ds = a date string formatted as: 1594260000000
 * @returns {string} a date string formatted as: 6:00:00pm
 */
export function formatTime12hrWithSeconds(ds) {
  return ds ? `${getHours(ds)}:${getMinutes(ds)}:${getSeconds(ds)}${getMeridian(ds)}` : '';
}

/**
 * Being used in the scrubbar listening point time
 * @param {number} seconds
 * @returns {string} a date string formatted as: 3:20
 */
export function formatTimeMinutesSeconds_seconds(secs) {
  return secs ? `${getMinutes(secs, false)}:${getSeconds(secs)}` : '0:00';
}

/**
 * Being used in metadata for the last line's aired time
 * @returns {string} a local time zone name abbrv string formatted as: MDT or MST
 */
export function formatLocalTimeZoneAbbrv() {
  return getLocalTimeZoneAbbrv();
}

/** ***************************************************************************************************
    Date Util Functions - add as needed, keep a very small footprint in each.
***************************************************************************************************** */
// eslint-disable-next-line prettier/prettier
const MONTHS_LONG = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const WEEKDAY_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
const WEEKDAY_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
const ORDINALS = ['st', 'nd', 'rd', 'th'];
const MERIDIENS = ['am', 'pm'];
const TIME_ZONES = {
  Atlantic: ['AST', -4, 'ADT', -3],
  Eastern: ['EST', -5, 'EDT', -4],
  Central: ['CST', -6, 'CDT', -5],
  Mountain: ['MST', -7, 'MDT', -6],
  Pacific: ['PST', -8, 'PDT', -7],
  Alaska: ['AKST', -9, 'AKDT', -8],
  Hawaii: ['HST', -10],
  Samoa: ['SST', -11],
  Chamorro: ['ChST', +10],
};

/*_______________________________________________________________________________________________*
 * DATE FUNCTIONS
 *_______________________________________________________________________________________________*/
// function getYear(dateString, type = 'full') {
//   return type === 'full' ? new Date(dateString).getFullYear() : new Date(dateString).getYear();
// }

export function getDate(dateString) {
  return new Date(dateString).getDate();
}

// type can = anything but 'full', like 'short', if you want the abbrv. month returned.
export function getMonth(dateString, type = 'full') {
  const monthNum = new Date(dateString).getMonth();
  return type === 'full' ? MONTHS_LONG[monthNum] : MONTHS_SHORT[monthNum];
}

// type can = anything but 'full', like 'short', if you want the abbrv. weekday returned.
export function getWeekday(dateString, type = 'full') {
  const dayNum = new Date(dateString).getDay();
  return type === 'full' ? WEEKDAY_LONG[dayNum] : WEEKDAY_SHORT[dayNum];
}

export function getOrdinal(dateString) {
  const dn = getDate(dateString);

  if (dn === 1 || dn === 21 || dn === 31) return ORDINALS[0];
  else if (dn === 2 || dn === 22) return ORDINALS[1];
  else if (dn === 3 || dn === 23) return ORDINALS[2];
  return ORDINALS[3];
}

/*_______________________________________________________________________________________________*
 * TIME FUNCTIONS
 *_______________________________________________________________________________________________*/
// type can = anything but '12hr', like '24hr', if you want the returned hour in the 24hour format.
export function getHours(dateString, type = '12hr') {
  const hr24Num = new Date(dateString).getHours();
  if (type !== '12hr') return hr24Num;

  let hr12Num;

  if (hr24Num >= 13) hr12Num = hr24Num - 12;
  else if (hr24Num === 0) hr12Num = 12;
  else hr12Num = hr24Num;

  return hr12Num.toString();
}

export function getMinutes(dateString, pad = true) {
  const minutes = new Date(dateString).getMinutes();
  return pad === true ? minutes.toString().padStart(2, '0') : minutes.toString();
}

export function getSeconds(dateString) {
  const seconds = new Date(dateString).getSeconds();
  return seconds.toString().padStart(2, '0');
}

export function getMeridian(dateString) {
  return new Date(dateString).getHours() < 12 ? MERIDIENS[0] : MERIDIENS[1];
}

export function getLocalTimeZoneAbbrv() {
  const now = new Date().toString();
  // filter will return an array with one entry to take the first item as the time zone
  // i.e. ['Atlantic', ['AST', -4, 'ADT', -3]]
  const timezone = Object.entries(TIME_ZONES).filter(entry => now.includes(entry[0]))[0];

  if (timezone) {
    return now.includes('Standard') ? timezone[1][0] : timezone[1][2];
  }

  return '';
}
