<template>
  <div class="errorMessage">
    <span class="errorIcon" v-if="showIcon"></span>
    <span class="errorMessageText">{{ error.message }}</span>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    error: {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/_variables2.scss';

.errorMessage {
  line-height: 23px;
  font-family: $fnt_MaisonNeue-Ext-Bold;
  font-size: 20px;
  color: $clr_white_white;
  letter-spacing: $fnt_letter_spacing_05;
}

/* should there be an icon component? */
.errorIcon {
  background: $svg_warning_icon no-repeat center center;
  background-size: cover;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
}

@media only screen and (min-width: 0px) and (max-width: 701px) {
  .errorMessageText {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.11px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 435px) {
  .errorIcon {
    vertical-align: middle;
  }

  .errorMessageText {
    vertical-align: middle;
    display: inline-block;
    width: calc(100% - 40px);
    margin-top: 6px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
  }
}
</style>
